import { BlockData } from "../ExpertGame.interface";

const youthFontanAnimation = require("../../../../../../../assets/birthday10/map/youth-fontan-animation.json");
const youthFontanFlipAnimation = require("../../../../../../../assets/birthday10/map/youth-fontan-flip-animation.json");

const youthFontanItemsPos = [
  [1515, 746],
  [1539, 784],
  [1567, 780],
  [1594, 815],
  [1554, 727],
  [1578, 765],
  [1606, 761],
  [1633, 796],
  [1591, 700],
  [1615, 738],
  [1643, 734],
  [1670, 769],
];
const youthFontanAnimations = youthFontanItemsPos.map((pos, i) => ({
  animationSrc: i % 2 ? youthFontanFlipAnimation : youthFontanAnimation,
  animationRepeat: true,
  pos,
}));
const youthImg = require("../../../../../../../assets/birthday10/map/youth.png").default;
const youthSrc = [
  youthImg,
  {
    src: require("../../../../../../../assets/birthday10/map/youth-2.png").default,
    pos: [1192, 944],
  },
  ...youthFontanAnimations,
];
const youthCompletedSrc = [
  youthImg,
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/youth-cubes-animation.json"),
    pos: [811, 591],
  },
  ...youthFontanAnimations,
];

const ecologyImg = require("../../../../../../../assets/birthday10/map/ecology.png").default;
const ecologySrc = [
  ecologyImg,
  {
    src: require("../../../../../../../assets/birthday10/map/ecology-2.png").default,
    pos: [470, 394],
  },
];
const ecologyCompletedSrc = [
  ecologyImg,
  {
    src: require("../../../../../../../assets/birthday10/map/ecology-3.png").default,
    pos: [599, 328],
  },
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/ecology-kvest-animation.json"),
    pos: [-238, -61],
  },
  {
    src: require("../../../../../../../assets/birthday10/map/ecology-4.png").default,
    pos: [598, 654],
  },
];

const entrepreneurshipSrc = [require("../../../../../../../assets/birthday10/map/entrepreneurship.png").default];
const entrepreneurshipCompletedSrc = [
  ...entrepreneurshipSrc,
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/entrepreneurship-lupa-animation.json"),
    pos: [-304, -336],
  },
];

const socialSrc = [require("../../../../../../../assets/birthday10/map/social.png").default];
const socialComletedSrc = [
  ...socialSrc,
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/social-lupa-animation.json"),
    pos: [-245, -371],
  },
];

const railwaySrc = [require("../../../../../../../assets/birthday10/map/railway.png").default];
const railwayCompletedSrc = [
  ...railwaySrc,
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/railway-lupa-animation.json"),
    pos: [497, -301],
  },
];

const zooSrc = [
  require("../../../../../../../assets/birthday10/map/zoo.png").default,
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/zoo-tigr-animation.json"),
    animationRepeat: true,
    pos: [947, 332],
  },
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/zoo-slon-animation.json"),
    animationRepeat: true,
    pos: [1132, 361],
  },
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/zoo-jiraf-animation.json"),
    animationRepeat: true,
    pos: [1275, 426],
  },
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/zoo-lebedy-animation.json"),
    animationRepeat: true,
    pos: [875, 705],
  },
];
const zooCompletedSrc = [
  ...zooSrc,
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/zoo-lupa-animation.json"),
    pos: [-169, -272],
  },
];

const parkImg = require("../../../../../../../assets/birthday10/map/park.png").default;
const park2 = { src: require("../../../../../../../assets/birthday10/map/park-2.png").default, pos: [835, 154] };
const parkSrc = [
  parkImg,
  { src: require("../../../../../../../assets/birthday10/map/park-3.png").default, pos: [633, 645] },
  park2,
];
const parkCompletedSrc = [
  parkImg,
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/park-shezlongy-animation.json"),
    pos: [361, 258],
  },
  park2,
];

const improvmentImg = require("../../../../../../../assets/birthday10/map/improvment.png").default;
const improvmentSrc = [
  improvmentImg,
  {
    src: require("../../../../../../../assets/birthday10/map/improvment-2.png").default,
    pos: [1020, 165],
  },
  {
    src: require("../../../../../../../assets/birthday10/map/improvment-3.png").default,
    pos: [591, 796],
  },
];
const improvmentCompletedSrc = [
  improvmentImg,
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/improvment-sportground-animation.json"),
    pos: [300, -500],
  },
  {
    src: require("../../../../../../../assets/birthday10/map/improvment-4.png").default,
    pos: [1123, 159],
  },
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/improvment-car-animation.json"),
    pos: [290, 625],
  },
];

const expertGameBlocks: Omit<BlockData, "pinRelativePos" | "pinRelativeCompletedPos">[] = [
  {
    id: "improvement",
    bgSrc: improvmentSrc,
    bgCompletedSrc: improvmentCompletedSrc,
    pos: [1648, 469],
    pinPos: [3337, 1318],
    pinCompletedPos: [3163, 1310],
  },
  {
    id: "movement",
    bgSrc: [require("../../../../../../../assets/birthday10/map/movement.png").default],
    pos: [4083, 465.65],
    pinPos: [5135, 1632.03],
  },
  {
    id: "transport",
    bgSrc: [
      require("../../../../../../../assets/birthday10/map/transport.png").default,
      {
        src: require("../../../../../../../assets/birthday10/map/transport-ship.svg").default,
        pos: [281, 66],
      },
    ],
    pos: [41, 1350],
    pinPos: [1939, 2256],
  },
  {
    id: "ecology",
    bgSrc: ecologySrc,
    bgCompletedSrc: ecologyCompletedSrc,
    pos: [2915.92, 1245.14],
    pinPos: [3812.98, 2256],
    pinCompletedPos: [3819.99, 2256],
  },
  {
    id: "parks",
    bgSrc: parkSrc,
    bgCompletedSrc: parkCompletedSrc,
    pos: [1744, 1794],
    pinPos: [2712.82, 2636.85],
    pinCompletedPos: [2713, 2636],
  },
  {
    id: "social",
    bgSrc: socialSrc,
    bgCompletedSrc: socialComletedSrc,
    pos: [4199.99, 1854.01],
    pinPos: [5550, 2829],
  },
  {
    id: "digital",
    bgSrc: zooSrc,
    bgCompletedSrc: zooCompletedSrc,
    pos: [576, 2461.17],
    pinPos: [924, 3407],
    pinCompletedPos: [924, 3407],
  },
  {
    id: "dpir",
    bgSrc: entrepreneurshipSrc,
    bgCompletedSrc: entrepreneurshipCompletedSrc,
    pos: [3036, 2523],
    pinPos: [3627, 3566],
  },
  {
    id: "culture",
    bgSrc: [
      require("../../../../../../../assets/birthday10/map/culture.png").default,
      {
        animationSrc: require("../../../../../../../assets/birthday10/map/culture-fontan-animation.json"),
        animationRepeat: true,
        pos: [301, 671],
      },
      {
        src: require("../../../../../../../assets/birthday10/map/culture-2.png").default,
        pos: [459, 800],
      },
    ],
    pos: [1871.99, 3209.42],
    pinPos: [2570, 4026],
  },
  {
    id: "bicycle",
    bgSrc: [require("../../../../../../../assets/birthday10/map/scooters.png").default],
    pos: [4305.51, 3429],
    pinPos: [5032, 4352],
  },
  {
    id: "railway",
    bgSrc: railwaySrc,
    bgCompletedSrc: railwayCompletedSrc,
    pos: [0, 3809],
    pinPos: [1899, 4882],
  },
  {
    id: "molodezh",
    bgSrc: youthSrc,
    bgCompletedSrc: youthCompletedSrc,
    pos: [3139, 3947],
    pinPos: [4452.68, 4996.19],
    pinCompletedPos: [4452.68, 5016.19],
  },
];

export default expertGameBlocks;
