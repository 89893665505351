import { FC, useState } from "react";

import { AppModal } from "../../../../../containers/Modal/AppModal";
import Button from "../../Button/Button";

interface CompletedSphereModalProps {
  text: string;
  onClose: () => void;
  onProposeIdea?: () => void;
  onNext: () => void;
  position: "left" | "right";
  isGameFinished: boolean;
}

const CompletedSphereModal: FC<CompletedSphereModalProps> = ({
  onProposeIdea,
  onNext,
  position,
  onClose,
  text,
  isGameFinished,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleOnClose = () => {
    onClose();
    setIsOpen(false);
  };
  const handleOnNext = () => {
    if (isGameFinished) {
      handleOnClose();
    } else {
      onNext();
    }
  };

  return (
    <AppModal
      center
      classNames={{
        modal: `completed-sphere-modal crowd-birthday-10-modal ${
          position ? `crowd-birthday-10-modal--${position}` : ""
        }`,
      }}
      open={isOpen}
      blockScroll={true}
      closeOnEsc={true}
      closeOnOverlayClick={false}
      showHeader={false}
      onClose={handleOnClose}
      overlayId="crowd-birthday-10"
    >
      <div className="crowd-birthday-10-modal__content">
        <p className=" crowd-birthday-10-modal__title">Вы уже ответили правильно на этот вопрос!</p>

        <p className="crowd-birthday-10-modal__text">{text}</p>
        <div className="crowd-birthday-10-modal__buttons">
          <Button onClick={handleOnNext} text="Продолжить" type="outlined" />

          <Button onClick={() => onProposeIdea()} text="Предложить идею" />
        </div>
      </div>
    </AppModal>
  );
};

export default CompletedSphereModal;
