import dayjs from "dayjs";
import { Project } from "../types/Projects.interface";
import { RealizedIdeaProjectGroupDirection } from "../types/GroupDirection.interface";
import { ProjectInfo } from "../types/Banner.interface";

export function deadlineDateFormat(date) {
  if (!date) return "-";

  const minutesCoeff = 1000 * 60;
  const hoursCoeff = minutesCoeff * 60;
  const dayCoeff = hoursCoeff * 24;

  let diff = dayjs(date).diff(dayjs().toString());

  const days = Math.floor(diff / dayCoeff);
  const daysStr = days > 0 ? `${days}` : "";

  const resultString = diff > 60 * 1000 ? `${daysStr}` : "меньше минуты";

  return `${resultString}`;
}

export function findUrlParam(parameter, searchQuery?) {
  return new URLSearchParams(searchQuery).get(parameter);
}

export function removeUrlParam(parameter, history, addToHistory?) {
  const queryParams = new URLSearchParams(history.location.search);
  if (queryParams.get(parameter)) {
    queryParams.delete(parameter);
    if (addToHistory) {
      history.push({
        pathname: history.location.pathname,
        search: queryParams.toString(),
      });
    } else {
      history.replace({
        pathname: history.location.pathname,
        search: queryParams.toString(),
      });
    }
  }
}

export function forceMaxLengthOnAndroid(e, maxLength) {
  if (e.target.value.length > maxLength) {
    e.target.value = e.target.value.substring(0, maxLength);
  }
}

export function clearUrlParams() {
  const [location, search] = decodeURI(window.location.href).split("?");
  window.history.replaceState(null, null, location);
}

// String<"monthToMonthOfYear" | "finishYear" | "dateToDate" | "startYear">
export function getStringDate(project, type) {
  // TODO перевести на moment.js или angular-moment, если потребуется больше форматирования дат
  const toMonth = (date) => {
    return new Date(date).toLocaleString("ru", {
      month: "long",
    });
  };

  if (type === "monthToMonthOfYear") {
    const registrationStart = toMonth(project.start);
    const registrationFinish = toMonth(project.finish);
    const fullYear = new Date(project.finish).getFullYear();

    return registrationStart === registrationFinish
      ? `${registrationFinish} ${fullYear}`
      : `${registrationStart} - ${registrationFinish} ${fullYear}`;
  }

  if (type === "finishYear") {
    return `${new Date(project.finish).getFullYear()}`;
  }

  if (type === "startYear") {
    return `${dayjs(project.start).year()}`;
  }

  if (type === "dateToDate") {
    let { start, finish } = project;

    if (start && finish) {
      const fullFormat = "DD.MM.YYYY";
      const shortFormat = "DD.MM";
      let _start = dayjs(start);
      let _finish = dayjs(finish);
      start = _finish.year() === _start.year() ? _start.format(shortFormat) : _start.format(fullFormat);
      finish = _finish.format(fullFormat);

      return `${start} - ${finish}`;
    }

    return "";
  }
}

export function declOfNum(number, words) {
  return words[number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]];
}

export function validateName(firstName) {
  const regex = /^[а-яё]+-?([а-яё]+)?$/i;
  return regex.test(firstName);
}

export function validateEmail(email) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

export function validate(params) {
  const { firstName, lastName, email } = params;
  let firstNameErr, lastNameErr, emailErr;

  if (!firstName) {
    firstNameErr = "Введите имя";
  } else if (firstName.length < 2) {
    firstNameErr = "Минимальная длина имени 2 символа";
  } else if (!validateName(firstName)) {
    firstNameErr = 'Имя может состоять из символов кириллицы и знаков "-"';
  } else {
    firstNameErr = "";
  }

  if (!lastName) {
    lastNameErr = "Введите фамилию";
  } else if (lastName.length < 2) {
    lastNameErr = "Минимальная длина фамилии 2 символа";
  } else if (!validateName(lastName)) {
    lastNameErr = 'Фамилия может состоять из символов кириллицы и знаков "-"';
  } else {
    lastNameErr = "";
  }

  if (!email) {
    emailErr = "Введите email";
  } else if (!validateEmail(email)) {
    emailErr = "Неверный формат email (your@mail.ru)";
  } else {
    emailErr = "";
  }

  return { firstNameErr, lastNameErr, emailErr };
}

export function formatDateShort(date) {
  return dayjs(date).format("DD.MM.YYYY");
}

export function calculateRemainedTime(projectInfo) {
  let diff = dayjs(projectIsCurrent(projectInfo) ? projectInfo.finish : projectInfo.start).diff(dayjs(), "minute");
  let res = " ";

  if (diff < 60) {
    res = diff + 1 + declination(diff + 1, [" минута", " минуты", " минут"]);
  } else if (diff < 60 * 24) {
    diff = Math.ceil(diff / 60);
    res = diff + declination(diff, [" час", " часа", " часов"]);
  } else {
    diff = Math.ceil(diff / 60 / 24);
    res = diff + declination(diff, [" день", " дня", " дней"]);
  }

  return res.split(" ");
}

export function projectIsCurrent(projectInfo): boolean {
  if (projectInfo) {
    return (
      dayjs(projectInfo.start).diff(dayjs(), "minute") <= 0 && dayjs(projectInfo.finish).diff(dayjs(), "minute") >= 0
    );
  } else {
    return false;
  }
}

export function isCurrentProjectActive(project: Project) {
  if (!project) return false;

  const now = dayjs();
  const start = dayjs(project.start);
  const finish = dayjs(project.finish);

  const hasStarted = start.isBefore(now) || start.isSame(now);
  const hasNotFinished = finish.isAfter(now) || finish.isSame(now);

  return hasStarted && hasNotFinished;
}

export function projectIsAnnouncement(projectInfo) {
  if (projectInfo) {
    return dayjs(projectInfo.start).diff(dayjs(), "minute") > 0;
  }
}

export function projectIsFinished(projectInfo) {
  if (projectInfo) {
    return dayjs(projectInfo.finish).diff(dayjs(), "minute") < 0;
  }
}

export function formatProjectDates(projectInfo) {
  return dayjs(projectInfo.start).format("DD.MM") + " - " + dayjs(projectInfo.finish).format("DD.MM.YYYY");
}

export function declination(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
}

export function numberWithSpaces(x) {
  if (typeof x !== "undefined") {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
}

export function getCookie(name) {
  let matches = document.cookie.match(
    new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)")
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie(name, value, options = {}) {
  options = {
    path: "/",
    // при необходимости добавьте другие значения по умолчанию
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export function deleteCookie(name) {
  setCookie(name, "", {
    "max-age": -1,
  });
}

export function isGuid(stringToTest) {
  if (stringToTest[0] === "{") {
    stringToTest = stringToTest.substring(1, stringToTest.length - 1);
  }
  var regexGuid =
    /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
  return regexGuid.test(stringToTest);
}

export const debounce = (func, wait) => {
  let timeout;

  function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  executedFunction.cancel = () => clearTimeout(timeout);

  return executedFunction;
};

export function throttle(func, wait, options?) {
  var context, args, result;
  var timeout = null;
  var previous = 0;
  if (!options) options = {};
  var later = function () {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };

  function executedFunction() {
    var now = Date.now();
    if (!previous && options.leading === false) previous = now;
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  }

  executedFunction.cancel = () => clearTimeout(timeout);

  return executedFunction;
}

export function isMobile() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export function isMobileOrTab() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export function getCleanBase64(str) {
  return str.substring(str.indexOf(",") + 1);
}

export function arrayShallowEqual(a, b) {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}

export function objectShallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

export function shadeColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

const _createUserSystem = (name, code, url, color, img, isParticipant) => ({
  name,
  code,
  url,
  color,
  isParticipant,
  img,
});

export const getUserSystems = (userSystemsCodes) => [
  _createUserSystem(
    "Электронный дом",
    "ED",
    "https://ed.mos.ru/",
    "#E48C28",
    require("../assets/related-projects/icons/ed.svg").default,
    userSystemsCodes?.includes("ED")
  ),
  _createUserSystem(
    "Активный гражданин",
    "AG",
    "https://ag.mos.ru/home",
    "#007A6C",
    require("../assets/related-projects/icons/ag.svg").default,
    userSystemsCodes?.includes("AG")
  ),
  _createUserSystem(
    "Наш город",
    "NG",
    "https://gorod.mos.ru/",
    "#1ABC9C",
    require("../assets/related-projects/icons/gorod.svg").default,
    userSystemsCodes?.includes("NG")
  ),
  _createUserSystem(
    "Город заданий",
    "NG",
    "https://gz.mos.ru/",
    "#1ABC9C",
    require("../assets/related-projects/icons/gz.svg").default,
    userSystemsCodes?.includes("GZ")
  ),
  _createUserSystem(
    "Миллион призов",
    "NG",
    "https://ag-vmeste.ru/",
    "#1ABC9C",
    require("../assets/related-projects/icons/mp.svg").default,
    userSystemsCodes?.includes("AGV")
  ),
  _createUserSystem(
    "Мосуслуги Online",
    "CROWD",
    "https://www.mos.ru/uslugi/",
    "#CC2222",
    require("../assets/related-projects/icons/uslugiWhite.svg").default,
    true
  ),
];

export const getScrollbarSize = () => {
  const documentWidth = +document.documentElement.clientWidth;
  const windowsWidth = +window.innerWidth;
  return windowsWidth - documentWidth;
};

export const getFileSize = (file, si = false, dp = 1) => {
  let bytes = file.size;
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " б";
  }

  const units = ["Кб", "Мб", "Гб", "TB", "PB", "EB", "ZB", "YB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + " " + units[u];
};

export function hideElement(selector, multiple?) {
  const elements = multiple ? document.querySelectorAll(selector) : [document.querySelector(selector)];
  elements.forEach((el) => el && (el.style.display = "none"));
  return () => {
    elements.forEach((el) => el && (el.style.display = ""));
  };
}

// user utils
export function userHasRoleInProject(user, projectId) {
  if (!user || !user.roles || !user.roles.length) return false;

  return user.roles.map((r) => r.projectId).includes(projectId);
}

export function findRole(user, projectId) {
  return user?.roles?.find(function (role) {
    return role.projectId === projectId;
  });
}

export function getRoleType(user, projectId) {
  const role = findRole(user, projectId);
  if (!!role) {
    return role.type;
  }
}

export function isFrontman(user, frontmanIds) {
  return frontmanIds?.includes(user?.id);
}

export function isParticipant(user, projectId) {
  return getRoleType(user, projectId) === "PARTICIPANT";
}

export function isExParticipant(user, projectId) {
  return getRoleType(user, projectId) === "EX_PARTICIPANT";
}

export function isExpert(user, projectId) {
  return getRoleType(user, projectId) === "EXPERT" || getRoleType(user, projectId) === "CHIEF_EXPERT";
}

export function isTeamMember(user, projectId) {
  if (!!findRole(user, projectId)) {
    return findRole(user, projectId).projectTeamMember;
  }
}

export function userIsProjectParticipant(user, projectId) {
  if (!user || !user.roles || !user.roles.length) return false;

  return user.roles.map((r) => r.projectId).includes(projectId);
}

export function replaceNbsp(string) {
  return string.replace(/&nbsp;/g, " ");
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const safeRequire = (src) => {
  let _src;
  try {
    _src = require(src).default;
  } catch (err) {
    _src = null;
    console.log(err);
  }
  return _src;
};

export const sprintf = (str: string, ...args: (string | number)[]): string => {
  const gen = function* () {
    yield* args;
  };
  const iterator = gen();
  return str.replace(/\%s/g, (match) => {
    const replaceValue = String(iterator.next().value);
    return typeof replaceValue !== "undefined" ? replaceValue : match;
  });
};

export const stripHTML = (str: string) => {
  const div = document.createElement("div");
  div.innerHTML = str;
  return div.textContent || div.innerText || "";
};

export const trimSpaces = (str: string) => str.replace(/\s+/g, " ").trim();

export const isObject = (obj) => {
  const type = typeof obj;
  return type === "function" || (type === "object" && !!obj);
};

export const getTimestamp = (): number => Math.round(Date.now() / 1000);

export const getGroupDirectionIdeasCount = (projectGroupDirection?: RealizedIdeaProjectGroupDirection) =>
  projectGroupDirection
    ? projectGroupDirection.ideas.length +
      projectGroupDirection.sections.reduce((prev, { ideas }) => prev + ideas.length, 0)
    : 0;

export const getProgressValue = (
  project: ProjectInfo | Project,
  projectGroupDirections?: { waiting?: RealizedIdeaProjectGroupDirection; realized?: RealizedIdeaProjectGroupDirection }
) => {
  let waitingRealizedIdeaCount, realizedIdeaCount;
  if (projectGroupDirections) {
    waitingRealizedIdeaCount = getGroupDirectionIdeasCount(projectGroupDirections.waiting);
    realizedIdeaCount = getGroupDirectionIdeasCount(projectGroupDirections.realized);
  } else {
    waitingRealizedIdeaCount = project.waitingRealizedIdeaCount;
    realizedIdeaCount = project.realizedIdeaCount;
  }

  const totalCount = waitingRealizedIdeaCount + realizedIdeaCount;

  const isOnlyAccepted = waitingRealizedIdeaCount && !realizedIdeaCount;
  const isSomeRealized = waitingRealizedIdeaCount && realizedIdeaCount;
  const isAllRealized = realizedIdeaCount && realizedIdeaCount === totalCount;

  if (isOnlyAccepted) {
    return 0;
  }
  if (isSomeRealized) {
    return 100 / (totalCount / realizedIdeaCount);
  }
  if (isAllRealized) {
    return 100;
  }

  return 0;
};

export function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

export function makeMultiCriteriaSort(...criteria) {
  return (a, b) => {
    for (let i = 0; i < criteria.length; i++) {
      const curCriteriaComparatorValue = criteria[i](a, b);
      // if the comparison objects are not equivalent, return the value obtained
      // in this current criteria comparison
      if (curCriteriaComparatorValue !== 0) {
        return curCriteriaComparatorValue;
      }
    }
    return 0;
  };
}

export function renderChildrenByKey(children, key) {
  if (!children || !key) return;
  let _children = Array.isArray(children) ? children : [children];
  return _children.find((c) => c.key === key) || "";
}

/**
 * установить позицию posElement возле targetElement
 * приоритет расположения: справа, слева, снизу, сверху
 * @param targetElement
 * @param posElement
 */
export function positionElementTarget(targetElement: Element, posElement: HTMLElement, indent = 0) {
  const { left, bottom, right, top, width, height } = targetElement.getBoundingClientRect();
  const { width: popupWidth, height: popupHeight, top: popupTop, left: popupLeft } = posElement.getBoundingClientRect();
  const offsetLeftDiff = popupLeft - posElement.offsetLeft;
  const offsetTopDiff = popupTop - posElement.offsetTop;

  const tEdge = top - popupHeight / 2 + height / 2;
  const bEdge = tEdge + popupHeight;
  const lEdge = left - popupWidth - indent;
  const rEdge = right + popupWidth + indent;

  let leftPos: number;
  let topPos: number;
  if (lEdge > 0 || rEdge < window.innerWidth) {
    leftPos = rEdge < window.innerWidth ? rEdge - popupWidth : lEdge;
    topPos = tEdge < 0 ? 0 : bEdge > window.innerHeight ? window.innerHeight - popupHeight : tEdge;
  } else {
    const tEdge = top - popupHeight - indent;
    const bEdge = bottom + popupHeight + indent;
    const lEdge = left - popupWidth / 2 + width / 2;
    const rEdge = lEdge + popupWidth;
    leftPos = lEdge < 0 ? 0 : rEdge > window.innerWidth ? window.innerWidth - popupWidth : lEdge;
    topPos = bEdge < window.innerHeight ? bEdge - popupHeight : tEdge;
  }
  posElement.style.setProperty("left", `${leftPos - offsetLeftDiff}px`);
  posElement.style.setProperty("top", `${topPos - offsetTopDiff}px`);
}
export function deepClone(objectToClone) {
  return JSON.parse(JSON.stringify(objectToClone));
}

export function isUnited(project) {
  return project.hasSubProjects;
}

export function countWithSuprojects(project, projects = []) {
  const _project = deepClone(project);
  let waitingRealizedIdeaCount = _project.waitingRealizedIdeaCount;
  let realizedIdeaCount = _project.realizedIdeaCount;

  _project.subProjectIds?.forEach((id) => {
    const subProject = projects.find((project) => project.id === id);
    if (subProject) {
      waitingRealizedIdeaCount = waitingRealizedIdeaCount + (subProject?.waitingRealizedIdeaCount || 0);

      realizedIdeaCount = realizedIdeaCount + (subProject?.realizedIdeaCount || 0);
    }
  });

  _project.waitingRealizedIdeaCount = waitingRealizedIdeaCount;
  _project.realizedIdeaCount = realizedIdeaCount;

  return _project;
}

function _getAvatarSrcByName(fileName) {
  try {
    return require(`../assets/user/${fileName}.png`).default;
  } catch {
    return require(`../assets/user/undefined.png`).default;
  }
}

export function getAvatarSrc(author) {
  if (!author || !author.pictureId) return;

  if (!author.showName) {
    const gender = author.gender ? author.gender.toLowerCase() : "undefined";
    return _getAvatarSrcByName(`${gender}_1`);
  }

  if (isGuid(author.pictureId)) {
    return `${window.location.origin}/uploads/get/${author.pictureId}`;
  } else {
    return _getAvatarSrcByName(author.pictureId);
  }
}

export function getAvatarPictureSrc(imageId) {
  if (!imageId) return;

  if (isGuid(imageId) || imageId === "userid_admin") {
    // timestamp disables the cache!
    return "/uploads/get/" + imageId + "?time=" + Number.parseInt(new Date().getTime() / 1000 + "");
  } else {
    try {
      return require(`../assets/user/${imageId}.png`).default;
    } catch (e) {
      console.debug(e);
    }
  }
}

export function formatNumber(num) {
  // Convert number to string
  let str = num.toString();

  // Split the string into parts separated by 3 digits
  let parts = [];
  while (str.length > 3) {
    parts.unshift(str.slice(-3));
    str = str.slice(0, -3);
  }
  parts.unshift(str);

  // Add spaces between parts and return the formatted string
  return parts.join(" ");
}

export function limitNumberToMax(num, max) {
  if (num > max) {
    return max + "+";
  } else {
    return num;
  }
}

export const displayError = (title: string, err: string) => {
  const style = `
    color: red;
    border: 2px dashed red;
    padding: 10px;
    margin: 10px 0;
    display: inline-block;
  `;

  console.log(`%c${title} | ${err}`, style);
};

export const copyToClipboard = (link: string, isAbsolute: boolean) => {
  const field = document.createElement("textarea");
  field.innerText = isAbsolute ? link : `${window.location.origin}${link}`;
  document.body.appendChild(field);
  field.select();
  document.execCommand("copy");
  field.remove();
};

export var replaceHtmlEntites = (function () {
  var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
  var translate = {
    nbsp: " ",
    amp: "&",
    quot: '"',
    lt: "<",
    gt: ">",
  };
  return function (s) {
    return s.replace(translate_re, function (match, entity) {
      return translate[entity];
    });
  };
})();

export async function imageIdToBlob(attachmentId: string, fileName?: string) {
  try {
    const response = await fetch(`/uploads/get/${attachmentId}`);
    const blob = await response.blob();
    const file = new File([blob], fileName || "filename", { type: blob.type });

    return { file: file, id: attachmentId };
  } catch (error) {
    console.error(error);
  }
}

export function getPlainFromHtml(html: string): string {
  const tempEl = document.createElement("div");
  tempEl.innerHTML = html;
  return tempEl.textContent || tempEl.innerText || "";
}

export const animate = (duration: number, callback: (step: number) => void) => {
  const startTime = new Date().getTime();
  let prevFrameTime;

  const handler = () => {
    const frameTime = new Date().getTime() - startTime;

    if (frameTime >= duration) {
      callback(1);
    } else {
      if (prevFrameTime !== frameTime) {
        prevFrameTime = frameTime;
        callback(frameTime / duration);
      }

      requestAnimationFrame(handler);
    }
  };

  requestAnimationFrame(handler);
};

export const getDistanceBetweenTouches = (e: TouchEvent) =>
  Math.sqrt(
    Math.pow(e.touches[0].pageX - e.touches[1].pageX, 2) + Math.pow(e.touches[0].pageY - e.touches[1].pageY, 2)
  );
