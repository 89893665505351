import { FC, useEffect, useMemo, useRef } from "react";
import "./SphereCard.scss";

import { AppModal } from "../../../../../../containers/Modal/AppModal";
import Button from "../../../Button/Button";
import { Scrollbar } from "react-scrollbars-custom";
import { Sphere } from "../../../../services/CrowdBirthday10.service";
import { stripHTML } from "../../../../../../../utils";
import { useIsWideTablet } from "../../../../../../../hooks/useMedia";

interface SphereCardProps {
  position: "left" | "right" | null;
  text?: string;
  question?: string;
  isOpen: boolean;
  onClose: () => void;
  onStart: () => void;
  sphere: Sphere;
  isGameFinished: boolean;
  onProposeClick?: (sphere: Sphere) => void;
}

// CROWD-13490 ищем фразу в контенте с бека и оборачиваем ее в ссылку для открытия формы идеи
const proposeLinkPhrase = "заполните форму";
const proposeLinkAttr = "propose-link";

const SphereCard: FC<SphereCardProps> = ({
  sphere,
  isOpen,
  isGameFinished,
  onClose,
  onStart,
  onProposeClick,
  position,
}) => {
  const contentRef = useRef<HTMLDivElement>();
  const scrollbarRef = useRef<Scrollbar & HTMLDivElement>();
  const considerIdeaFirstOrderRef = useRef<HTMLDivElement>();
  const isWideTablet = useIsWideTablet();

  const onProposeClickRef = useRef(onProposeClick);
  useEffect(() => {
    onProposeClickRef.current = onProposeClick;
  }, [onProposeClick]);

  const text1WithProposeLink = useMemo(
    () =>
      stripHTML(sphere.text1).replace(
        new RegExp(`(${proposeLinkPhrase})`, "i"),
        `<a ${proposeLinkAttr} href="#">$1</a>`
      ),
    [sphere.text1]
  );

  useEffect(() => {
    const el = contentRef.current;
    if (el && isOpen) {
      const handler = (e: MouseEvent) => {
        if ((e.target as HTMLElement)?.hasAttribute?.(proposeLinkAttr)) {
          e.preventDefault();
          onProposeClickRef.current?.(sphere);
        }
      };
      el.addEventListener("click", handler);

      return () => {
        el.removeEventListener("click", handler);
      };
    }
  }, [isOpen, sphere]);

  useEffect(() => {
    if (scrollbarRef.current?.holderElement && considerIdeaFirstOrderRef.current) {
      if (isWideTablet) {
        scrollbarRef.current.holderElement.style.maxHeight = null;
      } else {
        const { bottom } = considerIdeaFirstOrderRef.current.getBoundingClientRect();
        scrollbarRef.current.holderElement.style.maxHeight = `${
          bottom + scrollbarRef.current.getScrollState().scrollTop - 170
        }px`;
      }
    }
  }, [isWideTablet, sphere]);

  return (
    <AppModal
      center
      classNames={{
        modal: `sphere-card crowd-birthday-10-modal ${position ? `crowd-birthday-10-modal--${position}` : ""}`,
      }}
      open={isOpen}
      blockScroll={true}
      closeOnEsc={true}
      closeOnOverlayClick={false}
      showHeader={false}
      onClose={onClose}
      overlayId="crowd-birthday-10"
      focusTrapped={false}
    >
      <div className="crowd-birthday-10-modal__content" ref={contentRef}>
        <p className="crowd-birthday-10-modal__title" dangerouslySetInnerHTML={{ __html: sphere.groupName }} />

        <Scrollbar
          ref={scrollbarRef}
          className="sphere-card__scroll crowd-birthday-10-scroll"
          noDefaultStyles
          momentum
          translateContentSizeYToHolder
        >
          <div className="crowd-birthday-10-modal__content">
            <p className="crowd-birthday-10-modal__text">{sphere.description}</p>

            <div className="sphere-card__consider-idea">
              <p className=" crowd-birthday-10-modal__title">Как рассматриваются идеи</p>
              <div className="sphere-card__consider-idea-order" ref={considerIdeaFirstOrderRef}>
                <div className="sphere-card__consider-idea-order__img">
                  <img src={require("../../../../../../../assets/birthday10/lamp.png").default} alt="" />
                </div>
                <p
                  className="sphere-card__consider-idea-order__text"
                  dangerouslySetInnerHTML={{ __html: text1WithProposeLink }}
                />
              </div>
              <div className="sphere-card__consider-idea-order">
                <div className="sphere-card__consider-idea-order__img">
                  <img src={require("../../../../../../../assets/birthday10/scales.png").default} alt="" />
                </div>
                <p className="sphere-card__consider-idea-order__text">{sphere.text2}</p>
              </div>
              <div className="sphere-card__consider-idea-order">
                <div className="sphere-card__consider-idea-order__img">
                  <img src={require("../../../../../../../assets/birthday10/hourglass.png").default} alt="" />
                </div>
                <p className="sphere-card__consider-idea-order__text">{sphere.text3}</p>
              </div>
            </div>
          </div>
        </Scrollbar>
      </div>

      {!isGameFinished && <Button onClick={onStart} text="Играть" />}
    </AppModal>
  );
};
export default SphereCard;
