import { FC } from "react";
import "./SphereQuizCard.scss";

import { AppModal } from "../../../../../../containers/Modal/AppModal";
import Button from "../../../Button/Button";

interface SphereQuizCardProps {
  position: "left" | "right" | null;
  questionDescription: string;
  question: string;
  isOpen: boolean;
  onClose: () => void;
  onAnwser: (value: boolean) => void;
  isDisabled?: boolean;
}

const SphereQuizCard: FC<SphereQuizCardProps> = ({
  isOpen,
  onClose,
  onAnwser,
  position,
  isDisabled,
  questionDescription,
  question,
}) => {
  return (
    <AppModal
      center
      classNames={{
        modal: `sphere-quiz-card crowd-birthday-10-modal ${position ? `crowd-birthday-10-modal--${position}` : ""}`,
      }}
      open={isOpen}
      blockScroll={true}
      closeOnEsc={true}
      closeOnOverlayClick={false}
      showHeader={false}
      onClose={onClose}
      overlayId="crowd-birthday-10"
    >
      <div className=" crowd-birthday-10-modal__content">
        <p className=" crowd-birthday-10-modal__title">Идея</p>

        <div className="expert-game-modal__description">
          <div className="expert-game-modal__img">
            <img src={require("../../../../../../../assets/birthday10/question.svg").default} alt="" />
          </div>
          <p className="crowd-birthday-10-modal__text">{questionDescription}</p>
        </div>

        <p className="crowd-birthday-10-modal__title">{question}</p>

        <div className="crowd-birthday-10-modal__buttons">
          <Button onClick={() => onAnwser(true)} isDisabled={isDisabled} text="Да, можно" />
          <Button onClick={() => onAnwser(false)} isDisabled={isDisabled} text="Нет, нельзя" type="outlined" />
        </div>
      </div>
    </AppModal>
  );
};
export default SphereQuizCard;
