import React from "react";

const InputWithArrow = React.forwardRef(({ isListVisible, label, children, ...props }, ref) => {
  return (
    <div {...props} ref={ref}>
      <div className={`ui-icon-chevron-down-rounded ${isListVisible ? 'open' : ''}`} />
      {children}
    </div>
  );
});

export default InputWithArrow
