import { FC, useState } from "react";
import "./WelcomeModal.scss";

import { AppModal } from "../../../../../containers/Modal/AppModal";
import Button from "../../Button/Button";

export type WelcomeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onStart: () => void;
};

const WelcomeModal: FC<WelcomeModalProps> = ({ isOpen, onClose, onStart }) => {
  return (
    <AppModal
      center
      classNames={{
        modal: "welcome-modal crowd-birthday-10-modal",
      }}
      open={isOpen}
      blockScroll={true}
      closeOnEsc={true}
      closeOnOverlayClick={false}
      showHeader={false}
      onClose={onClose}
      overlayId="crowd-birthday-10"
    >
      <div className="crowd-birthday-10-modal__content">
        <div>
          <p className="welcome-modal__headline">
            10 ЛЕТ ПЛАТФОРМЕ
            <br />
            «ГОРОД ИДЕЙ»
          </p>
          <p className="welcome-modal__subheadline">Менять любимый город легко</p>
        </div>

        <p className="crowd-birthday-10-modal__text">
          Москва – город, в котором сбываются мечты, а идеи жителей воплощаются в жизнь! Каждый из нас может направить
          свои инициативы по развитию столицы, а после наблюдать как они реализуются.
        </p>

        <p className="crowd-birthday-10-modal__text">
          Сегодня мы приглашаем вас посмотреть, как изменился город благодаря идеям москвичей, предложенным на платформе
          «Город идей». Участвуйте в играх, зарабатывайте баллы и взгляните, как похорошела Москва!
        </p>

        <div className="welcome-modal__button">
          <img
            className="welcome-modal__stars"
            src={require("../../../../../../assets/birthday10/left-stars.svg").default}
            alt=""
          />

          <Button onClick={onStart} text="Начать" />

          <img
            className="welcome-modal__stars"
            src={require("../../../../../../assets/birthday10/right-stars.svg").default}
            alt=""
          />
        </div>
      </div>
    </AppModal>
  );
};

export default WelcomeModal;
