import Platform from "../../../../../services/platform";
import { wideTabletWidth } from "../../../../../utils/constants/widthConstants";
import { scaleStep, scaleStepsDesktop, scaleStepsTablet } from "./Map.constants";
import { MapInstance, MapTranslate } from "./Map.interface";

export const getMinScale = (mapInstance: MapInstance) =>
  Math.max(
    mapInstance.wrapper.clientWidth / mapInstance.container.clientWidth,
    mapInstance.wrapper.clientHeight / mapInstance.container.clientHeight
  );

export const getMaxScale = (mapInstance: MapInstance) => {
  const minScale = getMinScale(mapInstance);
  const isTablet = Platform.maxWidth(wideTabletWidth);
  return Math.floor((minScale + scaleStep * (isTablet ? scaleStepsTablet : scaleStepsDesktop)) * 1000) / 1000;
};

export const getLimitedTranslate = (translate: MapTranslate, mapInstance: MapInstance) => {
  const { wrapper, container } = mapInstance;
  const minScale = getMinScale(mapInstance);
  const maxScale = getMaxScale(mapInstance);
  const scale = Math.min(maxScale, Math.max(minScale, translate.scale));
  const width = container.clientWidth * scale;
  const height = container.clientHeight * scale;
  const leftPosMin = wrapper.clientWidth - width;
  const topPosMin = wrapper.clientHeight - height;
  const x = translate.x > 0 ? 0 : translate.x < leftPosMin ? leftPosMin : translate.x;
  const y = translate.y > 0 ? 0 : translate.y < topPosMin ? topPosMin : translate.y;
  return { x, y, scale };
};
