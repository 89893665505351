import { Route } from "react-router-dom";
import ChangePasswordContainer from "../components/containers/ChangePassword/ChangePasswordContainer";

import SetNewPasswordContainer from "../components/containers/SetNewPassword/SetNewPasswordContainer";
import RestorePasswordContainer from "../components/containers/RestorePassword/RestorePasswordContainer";
import SendRestoreEmailContainer from "../components/containers/SendRestoreEmail/SendRestoreEmailContainer";
import NewYearRulesPage from "../components/pages/NewYear/NewYearRulesPage";
import NotFoundPage from "../components/pages/NotFound/NotFoundPage";
import CompleteUserGuard from "./guards/CompleteUserGuard";
import LoggedInGuard from "./guards/LoggedInGuard";
import SmartRoute from "./SmartRoute";
import { notExistingURLs } from "../utils/constants";
import NotExistPage from "../components/pages/NotExist/NotExistPage";
import CrowdBirthday9 from "../components/pages/CrowdBirthday9/CrowdBirthday9";
import CrowdBirthday10 from "../components/pages/CrowdBirthday10/CrowdBirthday10";
import { birthday10LandingUrl } from "../components/pages/CrowdBirthday10/CrowdBirthday10.constants";

const baseRoutes = [
  <SmartRoute
    path="/teamLogin"
    exact
    component="TeamLogin"
    guards={[CompleteUserGuard]}
    lazy={true}
    shouldPreload={true}
    key={"TeamLogin"}
  />,
  <SmartRoute
    path="/profile"
    exact
    component="Account"
    guards={[LoggedInGuard]}
    lazy={true}
    shouldPreload={true}
    key={"Account"}
  />,
  <Route path="/newpassword" component={SetNewPasswordContainer} key={"NewPassword"} />,
  <Route path="/changePassword" component={ChangePasswordContainer} key={"ChangePassword"} />,
  <Route path="/restorePassword" component={RestorePasswordContainer} key={"RestorePassword"} />,
  <Route path="/restore" exact component={SendRestoreEmailContainer} key={"Restore"} />,
  <Route path="/gameRules" component={NewYearRulesPage} key={"GameRules"} />,
  <Route path="/birthday" component={CrowdBirthday9} key={"Birthday9"}></Route>,
  <Route path={birthday10LandingUrl} component={CrowdBirthday10} key={"Birthday10"}></Route>,
  ...notExistingURLs.map((url) => <Route key={url} path={url} component={NotExistPage} />),
  <Route component={NotFoundPage} key={"NotFoundPage"} />,
];

export default baseRoutes;
