import { FC } from "react";
import "./NewsAndPublicationsModal.scss";
import dayjs from "dayjs";
import { AppModal } from "../../../../../containers/Modal/AppModal";
import { News } from "../MediaPlatform";
import { Scrollbar } from "react-scrollbars-custom";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../../../utils/constants/widthConstants";

dayjs.locale("ru");

type NewsAndPublicationsModalProps = {
  onClose: () => void;
  news: News;
};

const NewsAndPublicationsModal: FC<NewsAndPublicationsModalProps> = ({ onClose, news }) => {
  const { header, newsText } = news;
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  return (
    <AppModal
      classNames={{ modal: "publication--modal" }}
      center
      open={true}
      overlayId="modal-overlay"
      closeIconId="close-modal-icon"
      onClose={onClose}
      blockScroll={true}
      closeIconSize={25}
      closeOnEsc={true}
      closeOnOverlayClick={false}
    >
      <div className="publication">
        <p className="publication__title" dangerouslySetInnerHTML={{ __html: header }}></p>
        {!isPhone ? (
          <Scrollbar
            className="publication__scroll discussion-landing-scroll"
            noDefaultStyles
            translateContentSizeYToHolder
          >
            <div className="publication__date">{dayjs(new Date()).format("DD MMMM YYYY, HH:MM")}</div>
            <div className="publication__text" dangerouslySetInnerHTML={{ __html: newsText }} />
          </Scrollbar>
        ) : (
          <>
            <div className="publication__date">{dayjs(new Date()).format("DD MMMM YYYY, HH:MM")}</div>
            <div className="publication__text" dangerouslySetInnerHTML={{ __html: newsText }} />
          </>
        )}
      </div>
    </AppModal>
  );
};
export default NewsAndPublicationsModal;
