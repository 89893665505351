import { ComponentProps, FC } from "react";
import ButtonPromo from "../../../../presentational/Controls/Button/Button";
import classNames from "classnames";

const Button: FC<ComponentProps<typeof ButtonPromo>> = (props) => (
  <div
    className={classNames(
      "crowd-birthday-10-button",
      props.type === "outlined" ? "crowd-birthday-10-button--outlined" : ""
    )}
  >
    <div className="crowd-birthday-10-button__border">
      <ButtonPromo {...props} />
    </div>
  </div>
);

export default Button;
