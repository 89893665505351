import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { IdeasGroupModalProps } from "./IdeasGroupModal.interface";
import "./IdeasGroupModal.scss";
import { AppModal } from "../../../../../containers/Modal/AppModal";
import classNames from "classnames";
import { uuidv4 } from "../../../../../../utils";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../../../utils/constants/widthConstants";
import Tooltip from "../../../../../presentational/Controls/Tooltip/Tooltip";
import { useIsWideTablet } from "../../../../../../hooks/useMedia";

const IdeasGroupModal: FC<IdeasGroupModalProps> = ({
  isOpen,
  onClose,
  ideaGroup,
  ideaGroupFound = false,
  onGroupIdeaSelect,
  target,
  initialIdeaId,
}) => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isWideTablet = useIsWideTablet();
  const modalRef = useRef<Element>();
  const [modalId] = useState(uuidv4());
  const [pos, setPos] = useState([0, 0]);
  const [swiper, setSwiper] = useState<SwiperCore>();

  const initialSlide = useMemo(() => {
    const i = ideaGroup.ideas.findIndex(({ id }) => id === initialIdeaId);
    return ~i ? i : 0;
  }, [initialIdeaId]);

  useLayoutEffect(() => {
    modalRef.current = document.getElementById(modalId);
  }, []);

  useLayoutEffect(() => {
    if (target) {
      const sideIndent = 20;
      const modalIndent = 20;
      const { top, left, width, height } = target.getBoundingClientRect();
      const { width: modalWidth, height: modalHeight } = modalRef.current.getBoundingClientRect();
      const leftHalfPos = left + width / 2 - modalWidth / 2;
      const rightHalfPos = left + width / 2 + modalWidth / 2;
      const topHalfPos = top + height / 2 - modalHeight / 2;
      const topHalfPosLimited = Math.max(
        sideIndent,
        Math.min(window.innerHeight - sideIndent - modalHeight, topHalfPos)
      );
      const canHalfHorizontal = leftHalfPos >= modalIndent && rightHalfPos <= window.innerWidth - modalIndent;
      let canTop = false,
        canBottom = false,
        canLeft = false;
      if (top >= modalHeight + sideIndent + modalIndent && canHalfHorizontal) {
        canTop = true;
      } else if (top + height + modalHeight + sideIndent + modalIndent <= window.innerHeight && canHalfHorizontal) {
        canBottom = true;
      } else if (left - sideIndent - modalWidth >= modalIndent) {
        canLeft = true;
      }

      if (canTop) {
        setPos([leftHalfPos, top - modalHeight - modalIndent]);
      } else if (canBottom) {
        setPos([leftHalfPos, top + height + modalIndent]);
      } else if (canLeft) {
        setPos([left - modalWidth - modalIndent, topHalfPosLimited]);
      } else {
        setPos([Math.min(left + width + modalIndent, window.innerWidth - sideIndent - modalWidth), topHalfPosLimited]);
      }
    }
  }, [target]);

  useEffect(() => {
    swiper?.update();
  }, [swiper]);

  const nav = ideaGroup.ideas.length > 1 && (
    <div className="crowd-birthday-10-ideas-group__nav">
      <div className="crowd-birthday-10-ideas-group__nav-left" onClick={() => swiper.slidePrev()} />
      <div className="crowd-birthday-10-ideas-group__nav-right" onClick={() => swiper.slideNext()} />
    </div>
  );

  const tooltipId = `crowd-birthday-10-ideas-group-${ideaGroup.id}`;

  const overridePosition = (position, currentEvent, currentTarget, node) => {
    return {
      left: position.left + currentTarget.clientWidth / 2,
      top: position.top - 13,
    };
  };

  return (
    <AppModal
      modalId={modalId}
      styles={{ modal: !isPhone ? { left: pos[0], top: pos[1] } : undefined }}
      center={!target || isPhone}
      classNames={{
        modal: classNames("crowd-birthday-10-ideas-group", "crowd-birthday-10-modal", {
          "crowd-birthday-10-ideas-group__reset-pos": !!target && !isPhone,
        }),
      }}
      open={isOpen}
      blockScroll={true}
      closeOnEsc={true}
      closeOnOverlayClick={false}
      showHeader={false}
      onClose={onClose}
      overlayId="crowd-birthday-10"
    >
      <div className="crowd-birthday-10-modal__content">
        <div className="crowd-birthday-10-ideas-group__success-text">РЕАЛИЗОВАНО В ГОРОДЕ НА ОСНОВЕ ИДЕЙ ЖИТЕЛЕЙ</div>
        <div className="crowd-birthday-10-modal__title">{ideaGroup.name}</div>
        <Swiper
          spaceBetween={10}
          onSwiper={setSwiper}
          loop={ideaGroup.ideas.length > 1}
          initialSlide={initialSlide}
          slidesPerView="auto"
          threshold={40}
        >
          {ideaGroup.ideas.map((idea) => (
            <SwiperSlide
              key={idea.id}
              className={classNames("crowd-birthday-10-ideas-group__idea", {
                "crowd-birthday-10-ideas-group__idea_unreal": !idea.actual && !ideaGroupFound,
                "crowd-birthday-10-ideas-group__idea_found": !idea.actual && ideaGroupFound,
              })}
            >
              <img src={`/uploads/get/${idea.pictureId}`} onClick={() => onGroupIdeaSelect?.(ideaGroup, idea)} />
              <div className="crowd-birthday-10-modal__text crowd-birthday-10-ideas-group__idea__text">
                <div>{idea.ideaText}</div>
                <div>{idea.address}</div>
              </div>
              {nav}
            </SwiperSlide>
          ))}
        </Swiper>
        <Link to="/ideas" target="blank" className="crowd-birthday-10-ideas-group__photo-link">
          Фото других реализованных идей
        </Link>
      </div>
      <Tooltip
        text="Когда найдете ненастоящую идею, нажмите на ее изображение."
        idx={tooltipId}
        place="top"
        outerClassName="crowd-birthday-10-ideas-group__tooltip"
        className="crowd-birthday-10-ideas-group__tooltip__inner"
        overridePosition={overridePosition}
        offset={{ right: isWideTablet ? -67 : -120 }}
      >
        <div data-for={tooltipId} className="crowd-birthday-10-ideas-group__tip" />
      </Tooltip>
    </AppModal>
  );
};

export default IdeasGroupModal;
