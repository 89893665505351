import { FC } from "react";
import { IdeasGroupAllFoundModalProps } from "./IdeasGroupAllFoundModal.interface";
import { AppModal } from "../../../../../containers/Modal/AppModal";
import Button from "../../Button/Button";
import { rulesLink } from "../../../CrowdBirthday10.constants";
import "./IdeasGroupAllFoundModal.scss";

const IdeasGroupAllFoundModal: FC<IdeasGroupAllFoundModalProps> = ({ isOpen, onClose, position }) => {
  return (
    <AppModal
      center
      classNames={{
        modal: `crowd-birthday-10-ideas-group-all-found crowd-birthday-10-modal ${
          position ? `crowd-birthday-10-modal--${position}` : ""
        }`,
      }}
      open={isOpen}
      blockScroll={true}
      closeOnEsc={true}
      closeOnOverlayClick={false}
      showHeader={false}
      onClose={onClose}
      overlayId="crowd-birthday-10"
    >
      <div className="crowd-birthday-10-modal__content">
        <div className="crowd-birthday-10-modal__title centralize-block">Поздравляем!</div>
        <div className="crowd-birthday-10-modal__text">
          Вы нашли все спрятанные ненастоящие идеи на странице.{" "}
          <a href={rulesLink} target="blank">
            Баллы
          </a>{" "}
          за победу будут начислены пользователям с полной учетной записью{" "}
          <a href="https://mos.ru" target="_blank">
            mos.ru
          </a>
          .
        </div>
        <div className="crowd-birthday-10-modal__buttons">
          <Button onClick={onClose} text="Хорошо" />
        </div>
      </div>
    </AppModal>
  );
};

export default IdeasGroupAllFoundModal;
