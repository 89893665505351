import { useEffect, useState } from "react";

export const useTextIsOverflowing = (textRef: React.RefObject<HTMLElement>, maxLines: number) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (maxLines && textRef.current && !isOverflowing) {
      const resizeObserver = new ResizeObserver((entries) => {
        const lines = entries[0].contentRect.height / parseInt(getComputedStyle(textRef.current).lineHeight);
        const isOverflowing = lines > maxLines;
        setIsOverflowing(isOverflowing);
      });

      resizeObserver.observe(textRef.current);
      return () => resizeObserver.disconnect();
    }
  }, [maxLines, isOverflowing, textRef]);

  return maxLines && isOverflowing;
};
