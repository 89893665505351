import { FC } from "react";
import "./ScreenStub.scss";

import { AppModal } from "../../../../../containers/Modal/AppModal";

const ScreenStub: FC = () => {
  return (
    <div className="crowd-birthday-10-screen-stub">
      <AppModal
        center
        classNames={{ modal: "crowd-birthday-10-screen-stub-modal  crowd-birthday-10-modal" }}
        open
        blockScroll={true}
        showHeader={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        overlayId="crowd-birthday-10-screen-stub-modal"
      >
        <div className="crowd-birthday-10-modal__content">
          <p className="crowd-birthday-10-modal__title">Рекомендуем открыть страницу на компьютере или ноутбуке</p>
          <p className="crowd-birthday-10-modal__text">
            Хотите продолжить здесь? Переверните мобильное устройство горизонтально.
          </p>
        </div>
      </AppModal>
    </div>
  );
};

export default ScreenStub;
