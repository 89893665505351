import { FC } from "react";

import { AppModal } from "../../../../../containers/Modal/AppModal";
import { rulesLink } from "../../../CrowdBirthday10.constants";
import "./WinModal.scss";
import Button from "../../Button/Button";

export interface WinModalProps {
  onClose: () => void;
  position: "left" | "right";
}

const WinModal: FC<WinModalProps> = ({ onClose, position }) => {
  return (
    <AppModal
      center
      classNames={{
        modal: `expert-game-win-modal crowd-birthday-10-modal ${
          position ? `crowd-birthday-10-modal--${position}` : ""
        }`,
      }}
      open={true}
      blockScroll={true}
      closeOnEsc={true}
      closeOnOverlayClick={false}
      showHeader={false}
      onClose={onClose}
      overlayId="crowd-birthday-10"
    >
      <div className="crowd-birthday-10-modal__content">
        <p className="crowd-birthday-10-modal__title centralize-block">Поздравляем! Вы выиграли!</p>

        <p className="crowd-birthday-10-modal__text">
          <a href={rulesLink} target="blank">
            Баллы
          </a>{" "}
          за победу в игре будут начислены пользователям <br /> с полной учетной записью{" "}
          <a href="https://www.mos.ru/" target="blank">
            mos.ru.
          </a>
        </p>

        <div className="crowd-birthday-10-modal__buttons">
          <Button onClick={onClose} text="Хорошо" />
        </div>
      </div>
    </AppModal>
  );
};

export default WinModal;
