import { useEffect } from "react";
import RedirectUrlService from "../services/redirectUrl.service";
import { ApiStatusCode } from "../types/Common.interface";
import { LocalStorageKeys, LocalStorageService } from "../services/LocalStorage.service";

const useRedirectUrl = () => {
  useEffect(() => {
    RedirectUrlService.getRedirectUrl()
      .then(({ status, data, message }) => {
        if (status === ApiStatusCode.OK) {
          const storedRaw = LocalStorageService.getData(LocalStorageKeys.REDIRECT_URL);
          if (data) {
            const storedData = storedRaw ? JSON.parse(storedRaw) : {};
            if (data.code !== storedData.code || data.url !== storedData.url) {
              LocalStorageService.saveData(LocalStorageKeys.REDIRECT_URL, JSON.stringify(data));
              window.location.href = data.url;
            }
          } else if (storedRaw) {
            LocalStorageService.removeData(LocalStorageKeys.REDIRECT_URL);
          }
        } else {
          console.error(message);
        }
      })
      .catch(console.error);
  }, []);
};

export default useRedirectUrl;
