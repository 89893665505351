import { API } from "../../../../services/api";
import { BaseResponse } from "../../../../types/Common.interface";

export interface LandingIdeaDto {
  id: string;
  number: number;
  ideaText: string;
  address: string;
  pictureId: string;
  published: boolean;
  actual: boolean;
}

export interface LandingIdeaGroupDto {
  id: string;
  name: string;
  published: boolean;
  number: number;
  ideas: LandingIdeaDto[];
}

export interface IdeasGameResult {
  finished: string | null;
  gameType: "ICONS" | "EXPERT" | null;
  groupIds: string[];
  id: string | null;
  rightAnswerCount: number;
  started: string | null;
  status: "FINISHED" | "IN_PROGRESS" | null;
  userId: string | null;
}

export interface IdeasGameSaveRequest {
  ideaId: string;
  groupId: string;
}

export interface ExpertGameSaveDataRequest {
  sphereId: string;
  answer: boolean;
}

export interface ExpertGameSaveDataResponse {
  id: string | null;
  userId: string | null;
  status: "FINISHED" | "IN_PROGRESS" | null;
  rightAnswerCount: number;
  started: string | null;
  finished: string | null;
  gameType: "Expert" | null;
  answers: Array<{
    sphereId: string;
    correct: boolean;
  }>;
}

export type ExpertGameResult = ExpertGameSaveDataResponse;

export interface Sphere {
  groupCode: string;
  id: string;
  description: string;
  descriptionAfterFinish: string;
  text1: string;
  text2: string;
  text3: string;
  number: number;
  question: string;
  questionDescription: string;
  correctAnswerMessage: string;
  notCorrectAnswerMessage: string;
  groupId: string;
  groupName: string;
}

const CrowdBirthday10Service = {
  async getSpheres() {
    const { data } = await API.get<Sphere[]>("/api/landingSphere/getAll");
    return data;
  },

  async saveExpertGame({ sphereId, answer }: ExpertGameSaveDataRequest) {
    const { data } = await API.post<BaseResponse<ExpertGameSaveDataResponse>>("api/landing/sphere/game/save", {
      sphereId,
      answer,
    });
    return data;
  },

  async getExpertGameResult() {
    const { data } = await API.get<BaseResponse<ExpertGameResult>>("/api/landing/sphere/game/getByCurrentUserId");
    return data;
  },

  async ideaGroupGetAll() {
    const { data } = await API.get<BaseResponse<LandingIdeaGroupDto[]>>("/api/landing/idea/group/getAll");
    return data;
  },

  async getIdeasGameResult() {
    const { data } = await API.get<BaseResponse<IdeasGameResult>>("/api/landing/idea/game/getByCurrentUserId");
    return data;
  },

  async getSpheresGameResult() {
    const { data } = await API.get<BaseResponse<IdeasGameResult>>("/api/landing/sphere/game/getByCurrentUserId");
    return data;
  },

  async saveIdeaGame(postData: IdeasGameSaveRequest) {
    const { data } = await API.post<BaseResponse<IdeasGameResult>>("/api/landing/idea/game/save", postData);
    return data;
  },
};

export default CrowdBirthday10Service;
