import { isMobile } from "react-device-detect";

const mapLayerImagesCtx = isMobile
  ? require.context("../../../../assets/birthday10/map-mobile/bg", false)
  : require.context("../../../../assets/birthday10/map/bg", false);
export const mapLayerImages = mapLayerImagesCtx.keys().map((item) => mapLayerImagesCtx(item).default);

const mapImagesCtx = isMobile
  ? require.context("../../../../assets/birthday10/map-mobile", false, /\.(svg|png)$/)
  : require.context("../../../../assets/birthday10/map", false, /\.(svg|png)$/);
export const preloadImages = [
  require("../../../../assets/birthday10/left-stars.svg").default,
  require("../../../../assets/birthday10/right-stars.svg").default,
  require("../../../../assets/birthday10/lamp.png").default,
  require("../../../../assets/birthday10/scales.png").default,
  require("../../../../assets/birthday10/hourglass.png").default,
  ...mapImagesCtx.keys().map((item) => mapImagesCtx(item).default),
  ...mapLayerImages,
];
