import { FC, useState, useEffect } from "react";
import "./InstructionModal.scss";
import { AppModal } from "../../../../../containers/Modal/AppModal";
import Button from "../../Button/Button";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Scrollbar } from "react-scrollbars-custom";
import { useMediaQuery } from "react-responsive";
import { MapInstance } from "../../Map/Map.interface";
import { Sphere } from "../../../services/CrowdBirthday10.service";
import cn from "classnames";
import { rulesLink } from "../../../CrowdBirthday10.constants";

interface InstructionModalProps {
  onClose: () => void;
  isMain?: boolean;
  spheres?: any;
  mapInstance?: MapInstance;
  startExpertGame?: (id?: any) => void;
  handleSphereSelect?: (sphere: Sphere) => void;
  skipInstaction: () => void;
  openSupportForm: () => void;
  selectedSphere: Sphere | null;
}

const InstructionModal: FC<InstructionModalProps> = ({
  spheres,
  onClose,
  isMain = false,
  startExpertGame,
  skipInstaction,
  handleSphereSelect,
  openSupportForm,
  selectedSphere,
}) => {
  const currentYear = dayjs().year();
  const [currentStep, setCurrentStep] = useState(1);

  const isMobile = useMediaQuery({ query: `(max-width: ${570}px)` });
  const isSpheresList = isMain && spheres && isMobile && currentStep === 1;

  const stepClasses = ["first", "second", "third"].map((stepClass, index) =>
    currentStep === index + 1 ? stepClass : ""
  );

  const handleNext = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    } else {
      onClose();
    }
  };

  const handleStart = () => {
    startExpertGame();
    onClose();
  };

  const handleSkipInstraction = () => {
    skipInstaction();
    onClose();
  };

  useEffect(() => {
    return () => {
      setCurrentStep(1);
    };
  }, [onClose]);

  const Footer = () => {
    return (
      <div className={cn("instruction-modal__footer", { "instruction-modal__footer--small": currentStep > 1 })}>
        <span className="footer-link footer-link__help" onClick={openSupportForm}>
          Помощь
        </span>
        <a className="footer-link footer-link__rules" href={rulesLink} target="blank">
          Правила участия
        </a>
        <div className="footer-link">
          <Link to={"/"} className="footer-link__promo" target="_blank">
            <span>Перейти на crowd.mos.ru</span>
            <div className="footer-link__promo__arrow"></div>
          </Link>
          <span className="copyright">© Краудсорсинг-проекты Правительства Москвы, {currentYear}</span>
        </div>
      </div>
    );
  };

  const SpheresList = () => (
    <div className="spheres__list">
      <div className="title">Выберите сферу</div>
      {spheres.map((sphere) => (
        <div
          key={sphere.id}
          className={`sphere ${selectedSphere && selectedSphere.id === sphere.id ? "active" : ""}`}
          onClick={() => {
            handleSphereSelect(sphere);
          }}
        >
          <span className="sphere__title" dangerouslySetInnerHTML={{ __html: sphere.groupName }} />
        </div>
      ))}
    </div>
  );

  return (
    <AppModal
      classNames={{
        modal: cn("crowd-birthday-10-modal instruction", {
          "instruction--left": !isMain && !isMobile,
          "instruction--right": isMain && !isMobile,
          "instruction--center": isMobile,
        }),
        overlay: "instruction__overlay",
      }}
      open={true}
      blockScroll={true}
      closeOnEsc={true}
      overlayId="crowd-birthday-10"
      onClose={onClose}
      closeOnOverlayClick={true}
    >
      <div className={`instruction-modal instruction-modal__content-wrapper ${stepClasses.join(" ")}`}>
        <div className="instruction-modal__header">
          <span className="title">Инструкция</span>
          <span className="counter">{currentStep}/3</span>
        </div>
        <Scrollbar
          className={`crowd-birthday-10-scroll instruction__scroll instruction-modal ${!isMain ? "left" : ""}`}
          noDefaultStyles
          momentum
        >
          <div className={`instruction-modal__content`}>
            {currentStep === 1 && (
              <>
                <span className="instruction-modal__text">
                  На тематической странице вы можете в игровом формате познакомиться со сферами раздела «Предложить
                  идею», уже реализованными инициативами и предложить собственные! А тем, кто успешно пройдет квесты,
                  будут начислены 600 баллов, которые можно обменять на товары и услуги на сайте «Миллион призов».
                  Подробнее в{" "}
                  <a href={rulesLink} target="blank">
                    Правилах участия
                  </a>
                  .
                </span>
              </>
            )}
            {currentStep === 2 && (
              <span className="instruction-modal__text">
                Нажав на оранжевый значок, вы можете пройти игру «Почувствуй себя экспертом». Ответьте на 12
                увлекательных вопросов и получите 500 городских баллов! Помните, что в каждом задании лишь один
                правильный ответ. Прогресс игрока отражается в счетчике на тематической странице.
              </span>
            )}
            {currentStep === 3 && (
              <span className="instruction-modal__text">
                На карте расположено множество карточек с реализованными идеями участников платформы «Город идей». В
                некоторых из них спрятаны выдуманные инициативы, которые необходимо отыскать в игре «Найди ненастоящие
                идеи». Самые внимательные пользователи, обнаружившие 10 ненастоящих предложений, получат 100 городских
                баллов! Прогресс игрока отражается в счетчике на тематической странице.
              </span>
            )}
          </div>
          <div className="instruction-modal__buttons">
            {currentStep < 3 ? (
              <>
                <Button key={currentStep} onClick={handleNext} text="Далее" />
                <Button onClick={handleSkipInstraction} type={"outlined"} text="Пропустить" classList="skip" />
              </>
            ) : (
              <Button onClick={handleStart} text="Старт" />
            )}
          </div>
          {isSpheresList && <SpheresList />}
        </Scrollbar>
      </div>
      {isMain && <Footer />}
    </AppModal>
  );
};

export default InstructionModal;
