import { BlockData } from "../ExpertGame.interface";

const youthFontanAnimation = require("../../../../../../../assets/birthday10/map/youth-fontan-animation.json");
const youthFontanFlipAnimation = require("../../../../../../../assets/birthday10/map/youth-fontan-flip-animation.json");

const youthFontanItemsPos = [
  [758, 373],
  [770, 392],
  [784, 390],
  [797, 408],
  [777, 364],
  [789, 383],
  [803, 381],
  [817, 398],
  [796, 350],
  [808, 369],
  [822, 367],
  [835, 385],
];
const youthFontanAnimations = youthFontanItemsPos.map((pos, i) => ({
  animationSrc: i % 2 ? youthFontanFlipAnimation : youthFontanAnimation,
  animationRepeat: true,
  pos,
  size: [400, 400],
}));
const youthImg = require("../../../../../../../assets/birthday10/map-mobile/youth.png").default;
const youthSrc = [
  youthImg,
  {
    src: require("../../../../../../../assets/birthday10/map-mobile/youth-2.png").default,
    pos: [596, 472],
  },
  ...youthFontanAnimations,
];
const youthCompletedSrc = [
  youthImg,
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/youth-cubes-animation.json"),
    pos: [406, 296],
    size: [2000, 2000],
  },
  ...youthFontanAnimations,
];

const ecologyImg = require("../../../../../../../assets/birthday10/map-mobile/ecology.png").default;
const ecologySrc = [
  ecologyImg,
  {
    src: require("../../../../../../../assets/birthday10/map-mobile/ecology-2.png").default,
    pos: [235, 197],
  },
];
const ecologyCompletedSrc = [
  ecologyImg,
  {
    src: require("../../../../../../../assets/birthday10/map-mobile/ecology-3.png").default,
    pos: [300, 164],
  },
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/ecology-kvest-animation.json"),
    pos: [-119, -31],
    size: [4000, 4000],
  },
  {
    src: require("../../../../../../../assets/birthday10/map-mobile/ecology-4.png").default,
    pos: [299, 327],
  },
];

const entrepreneurshipSrc = [require("../../../../../../../assets/birthday10/map-mobile/entrepreneurship.png").default];
const entrepreneurshipCompletedSrc = [
  ...entrepreneurshipSrc,
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/entrepreneurship-lupa-animation.json"),
    pos: [-152, -168],
    size: [5000, 5000],
  },
];

const socialSrc = [require("../../../../../../../assets/birthday10/map-mobile/social.png").default];
const socialComletedSrc = [
  ...socialSrc,
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/social-lupa-animation.json"),
    pos: [-123, -186],
    size: [5000, 5000],
  },
];

const railwaySrc = [require("../../../../../../../assets/birthday10/map-mobile/railway.png").default];
const railwayCompletedSrc = [
  ...railwaySrc,
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/railway-lupa-animation.json"),
    pos: [249, -151],
    size: [5000, 5000],
  },
];

const zooSrc = [
  require("../../../../../../../assets/birthday10/map-mobile/zoo.png").default,
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/zoo-tigr-animation.json"),
    animationRepeat: true,
    pos: [474, 166],
    size: [400, 400],
  },
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/zoo-slon-animation.json"),
    animationRepeat: true,
    pos: [566, 181],
    size: [1000, 1000],
  },
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/zoo-jiraf-animation.json"),
    animationRepeat: true,
    pos: [638, 213],
    size: [1000, 1000],
  },
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/zoo-lebedy-animation.json"),
    animationRepeat: true,
    pos: [438, 353],
    size: [1000, 1000],
  },
];
const zooCompletedSrc = [
  ...zooSrc,
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/zoo-lupa-animation.json"),
    pos: [-85, -136],
    size: [5000, 5000],
  },
];

const parkImg = require("../../../../../../../assets/birthday10/map-mobile/park.png").default;
const park2 = { src: require("../../../../../../../assets/birthday10/map-mobile/park-2.png").default, pos: [418, 77] };
const parkSrc = [
  parkImg,
  { src: require("../../../../../../../assets/birthday10/map-mobile/park-3.png").default, pos: [317, 323] },
  park2,
];
const parkCompletedSrc = [
  parkImg,
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/park-shezlongy-animation.json"),
    pos: [181, 129],
    size: [2000, 2000],
  },
  park2,
];

const improvmentImg = require("../../../../../../../assets/birthday10/map-mobile/improvment.png").default;
const improvmentSrc = [
  improvmentImg,
  {
    src: require("../../../../../../../assets/birthday10/map-mobile/improvment-2.png").default,
    pos: [510, 83],
  },
  {
    src: require("../../../../../../../assets/birthday10/map-mobile/improvment-3.png").default,
    pos: [296, 398],
  },
];
const improvmentCompletedSrc = [
  improvmentImg,
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/improvment-sportground-animation.json"),
    pos: [150, -250],
    size: [5000, 5000],
  },
  {
    src: require("../../../../../../../assets/birthday10/map-mobile/improvment-4.png").default,
    pos: [562, 80],
  },
  {
    animationSrc: require("../../../../../../../assets/birthday10/map/improvment-car-animation.json"),
    pos: [145, 313],
    size: [1000, 1000],
  },
];

const expertGameBlocksMobile: Omit<BlockData, "pinRelativePos" | "pinRelativeCompletedPos">[] = [
  {
    id: "improvement",
    bgSrc: improvmentSrc,
    bgCompletedSrc: improvmentCompletedSrc,
    pos: [824, 235],
    pinPos: [1669, 659],
    pinCompletedPos: [1582, 655],
  },
  {
    id: "movement",
    bgSrc: [require("../../../../../../../assets/birthday10/map-mobile/movement.png").default],
    pos: [2042, 233],
    pinPos: [2568, 816],
  },
  {
    id: "transport",
    bgSrc: [
      require("../../../../../../../assets/birthday10/map-mobile/transport.png").default,
      {
        src: require("../../../../../../../assets/birthday10/map-mobile/transport-ship.svg").default,
        pos: [141, 33],
      },
    ],
    pos: [20.5, 675],
    pinPos: [970, 1128],
  },
  {
    id: "ecology",
    bgSrc: ecologySrc,
    bgCompletedSrc: ecologyCompletedSrc,
    pos: [1458, 623],
    pinPos: [1907, 1128],
    pinCompletedPos: [1910, 1128],
  },
  {
    id: "parks",
    bgSrc: parkSrc,
    bgCompletedSrc: parkCompletedSrc,
    pos: [872, 897],
    pinPos: [1357, 1319],
    pinCompletedPos: [1357, 1318],
  },
  {
    id: "social",
    bgSrc: socialSrc,
    bgCompletedSrc: socialComletedSrc,
    pos: [2100, 927],
    pinPos: [2775, 1415],
  },
  {
    id: "digital",
    bgSrc: zooSrc,
    bgCompletedSrc: zooCompletedSrc,
    pos: [288, 1231],
    pinPos: [462, 1704],
    pinCompletedPos: [462, 1704],
  },
  {
    id: "dpir",
    bgSrc: entrepreneurshipSrc,
    bgCompletedSrc: entrepreneurshipCompletedSrc,
    pos: [1518, 1262],
    pinPos: [1814, 1783],
  },
  {
    id: "culture",
    bgSrc: [
      require("../../../../../../../assets/birthday10/map-mobile/culture.png").default,
      {
        animationSrc: require("../../../../../../../assets/birthday10/map/culture-fontan-animation.json"),
        animationRepeat: true,
        pos: [151, 336],
        size: [1600, 1600],
      },
      {
        src: require("../../../../../../../assets/birthday10/map-mobile/culture-2.png").default,
        pos: [230, 400],
      },
    ],
    pos: [936, 1605],
    pinPos: [1285, 2013],
  },
  {
    id: "bicycle",
    bgSrc: [require("../../../../../../../assets/birthday10/map-mobile/scooters.png").default],
    pos: [2153, 1715],
    pinPos: [2516, 2176],
  },
  {
    id: "railway",
    bgSrc: railwaySrc,
    bgCompletedSrc: railwayCompletedSrc,
    pos: [0, 1905],
    pinPos: [950, 2441],
  },
  {
    id: "molodezh",
    bgSrc: youthSrc,
    bgCompletedSrc: youthCompletedSrc,
    pos: [1570, 1974],
    pinPos: [2226, 2498],
    pinCompletedPos: [2226, 2508],
  },
];

export default expertGameBlocksMobile;
