import { FC } from "react";
import src from "../../../../assets/birthday10/banner.png";
import srcPhone from "../../../../assets/birthday10/banner-mobile.png";
import { useIsMobile } from "../../../../hooks/useMedia";
import { Link } from "react-router-dom";
import { birthday10LandingUrl } from "../../CrowdBirthday10/CrowdBirthday10.constants";
import "./Birthday10Banner.scss";

const Birthday10Banner: FC = () => {
  const isPhone = useIsMobile();
  return (
    <Link className="birthday10-banner" to={birthday10LandingUrl}>
      <img src={isPhone ? srcPhone : src} alt="Баннер" />
    </Link>
  );
};

export default Birthday10Banner;
