import { BaseResponse } from "../types/Common.interface";
import { API } from "./api";

interface RedirectUrl {
  url: string;
  code: string;
}

const baseUrl = "/api/redirectUrl";

const RedirectUrlService = {
  getRedirectUrl: () => {
    return API.get<BaseResponse<RedirectUrl>>(`${baseUrl}/getTurnOn`).then(({ data }) => {
      return data;
    });
  },
};

export default RedirectUrlService;
