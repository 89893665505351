import classNames from "classnames";
import { TSpecialProject } from "../../types/types";

interface TProps {
  specialProjects: TSpecialProject[];
  imageKey: keyof TSpecialProject["images"];
  activeSpecialProjectId: TSpecialProject["id"] | null;
}

export function SpecialProjectsImages({ specialProjects, imageKey, activeSpecialProjectId }: TProps) {
  return (
    <>
      {specialProjects.map((specialProject, index) => (
        <img
          alt="Главное изображение проекта"
          key={specialProject.id}
          className={classNames("special-projects__main__image", {
            "special-projects__main__image--active": activeSpecialProjectId
              ? activeSpecialProjectId === specialProject.id
              : !index,
          })}
          src={specialProject.images[imageKey].large}
        />
      ))}
    </>
  );
}
