import { Route } from "react-router-dom";
import DiscussionLandingPage from "../DiscussionLandingPage";
import DiscussionLandingContext from "../DiscussionLanding.context";
import { discussionLandingConfigs } from "../DiscussionLanding.constants";

const useMpRoutes = () => {
  return discussionLandingConfigs.map((config) => (
    <Route
      path={config.url}
      render={() => (
        <DiscussionLandingContext.Provider value={config}>
          <DiscussionLandingPage />
        </DiscussionLandingContext.Provider>
      )}
      key={config.mediaPlatformId}
    />
  ));
};

export default useMpRoutes;
