import { Link } from "react-router-dom";
import "./SpecialProjectsCard.scss";
import { Dispatch, SetStateAction } from "react";
import { TSpecialProject } from "../../types/types";

interface TProps {
  setHoveredLandingId: Dispatch<SetStateAction<TSpecialProject["id"]>>;
  specialProject: TSpecialProject;
  imageKey: keyof TSpecialProject["images"];
}

export function SpecialProjectsCard({ setHoveredLandingId, specialProject, imageKey }: TProps) {
  const handleMouseOver = (id: TProps["specialProject"]["id"]) => {
    setHoveredLandingId(id);
  };

  return (
    <Link
      className="special-projects-card"
      onMouseEnter={() => handleMouseOver(specialProject.id)}
      onMouseLeave={() => handleMouseOver(null)}
      to={specialProject.url}
      target="_blank"
    >
      <img
        className="special-projects-card__image"
        alt="Главное изображение проекта"
        src={specialProject.images[imageKey].little}
      />
      <div className="special-projects-card__bottom">
        <div className="special-projects-card__bottom__text">{specialProject.title}</div>
        <div className="special-projects-card__bottom__arrow" />
      </div>
    </Link>
  );
}
