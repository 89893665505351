export interface ExternalProject {
  name: string;
  title: string;
  url: string;
  text: string;
  color: string;
  buttonColor: string;
  logoSrc: string;
  logoPopupSrc: string;
}

export const projects: ExternalProject[] = [
  {
    name: "ed",
    title: "Электронный </br> дом",
    url: "https://ed.mos.ru/",
    text: "«Электронный дом» – эффективный инструмент для управления многоквартирными домами онлайн: упрощение принятия важных решений по дому и взаимодействия жителей и управляющих организаций в режиме онлайн. На платформе «Город идей» был проведен проект, направленный на развитие и улучшение проекта «Электронный дом».",
    color: "#E48C28",
    buttonColor: "#FF7D5D",
    logoSrc: require("../../../../assets/related-projects/icons/ed.svg").default,
    logoPopupSrc: require("../../../../assets/related-projects/ed.jpg").default,
  },
  {
    name: "ag",
    title: "Активный </br> гражданин",
    url: "https://ag.mos.ru/",
    text: "«Активный гражданин» ‒ проект, позволяющий участвовать в онлайн-голосованиях по различным вопросам городского развития: открытие новых станций метро, озеленение, утверждение стандартов работы различных организаций и др. Например, в рамках проекта проходило голосование по идеям участников проекта «Московский стандарт детского отдыха».",
    color: "#007A6C",
    buttonColor: "#007A6C",
    logoSrc: require("../../../../assets/related-projects/icons/ag.svg").default,
    logoPopupSrc: require("../../../../assets/related-projects/ag.svg").default,
  },
  {
    name: "gorod",
    title: "Наш город",
    url: "https://gorod.mos.ru/",
    text: "«Наш город» ‒ проект, созданный в целях улучшения качества жизни горожан и облика Москвы, позволяющий жителям в удобной форме контролировать качество выполнения работ городскими службами. На платформе «Город идей» было проведено два проекта, направленных на сбор идей жителей по улучшению и совершенствованию портала «Наш город».",
    color: "#1ABC9C",
    buttonColor: "#00D0B8",
    logoSrc: require("../../../../assets/related-projects/icons/gorod.svg").default,
    logoPopupSrc: require("../../../../assets/related-projects/gorod.jpg").default,
  },
  {
    name: "gz",
    title: "Город </br> заданий",
    url: "https://gz.mos.ru/landing",
    text: "«Город заданий» — электронный проект Правительства Москвы, который позволяет жителям лично поучаствовать в жизни города, в том числе в улучшении городской среды, выполняя различные онлайн- и офлайн-задания от партнеров проекта.",
    color: "#6D5DD3",
    buttonColor: "#6D5DD3",
    logoSrc: require("../../../../assets/related-projects/icons/gz.svg").default,
    logoPopupSrc: require("../../../../assets/related-projects/gz.jpg").default,
  },
  {
    name: "mp",
    title: "Миллион </br> призов",
    url: "https://ag-vmeste.ru",
    text: "«Миллион призов» — программа, направленная на стимулирование граждан к участию в голосованиях и общественно важных программах города, которая также способствует увеличению потребительского спроса. Участники программы получают баллы и скидки, которые можно использовать для оплаты в торговых сетях, аптеках, ресторанах и кафе.",
    color: "#1F3C83",
    buttonColor: "#1F3C83",
    logoSrc: require("../../../../assets/related-projects/icons/mp.svg").default,
    logoPopupSrc: require("../../../../assets/related-projects/mp.jpg").default,
  },
  {
    name: "uslugi",
    title: "Мосуслуги </br> Online",
    url: "https://www.mos.ru/uslugi/",
    text: "«Мосуслуги Online» – это проект, позволяющий получать городские услуги и сервисы в электронном виде, не выходя из дома, 24 часа в сутки 7 дней в неделю. На платформе «Город идей» проведены несколько проектов, направленных на усовершенствование предоставления электронных госуслуг в самых актуальных сферах жизни москвичей.",
    color: "#CC2222",
    buttonColor: "#F90819",
    logoSrc: require("../../../../assets/related-projects/icons/uslugiWhite.svg").default,
    logoPopupSrc: require("../../../../assets/related-projects/uslugi.jpg").default,
  },
  {
    name: "myid",
    title: "Мой id",
    url: "https://www.mos.ru/city/projects/mosapps/#myid",
    text: "Мобильное приложение «Мой id» позволяет просматривать ключевые сведения документов, чтобы их было удобно использовать в любой момент при заполнении онлайн-форм на сайтах, в приложениях, а также делиться с доверенными лицами.",
    color: "#ED1C28",
    buttonColor: "#ED1C28",
    logoSrc: require("../../../../assets/related-projects/icons/myid.svg").default,
    logoPopupSrc: require("../../../../assets/related-projects/myid.svg").default,
  },
  {
    name: "stories",
    title: "Сообщество",
    url: "https://stories.mos.ru",
    text: "«Сообщество» — это коллекция историй людей, которые сегодня живут в Москве. Мы познакомим вас с самыми интересными горожанами, их опытом, идеями и компетенциями. Мы расскажем, о чем они думают, чем занимаются, как болеют и выздоравливают, на чем ездят, где отдыхают и от чего грустят, как они меняют Москву днем и почему не спят ночью. Вы увидите их лица, услышите голоса и сможете поделиться с нами собственными историями.",
    color: "#212121",
    buttonColor: "#212121",
    logoSrc: require("../../../../assets/related-projects/icons/stories.svg").default,
    logoPopupSrc: require("../../../../assets/related-projects/stories.svg").default,
  },
];
