import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { lessenPage, widenPage } from "../../../store/actions/LayoutActions";
import AuthForm from "../../containers/Forms/AuthForm/AuthForm";

import "./CrowdBirthday10.scss";
import WelcomeModal from "./components/Modals/WelcomeModal/WelcomeModal";
import ScreenStub from "./components/Modals/ScreenStub/ScreenStub";
import ScreenLoader from "./components/ScreenLoader/ScreenLoader";
import Map from "./components/Map/Map";
import { ToastProvider } from "react-toast-notifications";
import ToastContainer from "./components/ToastContainer/ToastContainer";
import Block from "./components/Map/Block/Block";
import { MapInstance, MapProps } from "./components/Map/Map.interface";
import { objectsGatheringData } from "./components/Games/ObjectsGathering/ObjectsGathering.constants";
import Header, { HeaderIntance } from "./components/Header/Header";
import Pin from "./components/Map/Pin/Pin";
import ExpertGame, { ExportGameInstance } from "./components/Games/ExpertGame/ExpertGame";
import ObjectsGathering from "./components/Games/ObjectsGathering/ObjectsGathering";
import {
  ObjectsGatheringData,
  ObjectsGatheringInstance,
} from "./components/Games/ObjectsGathering/ObjectsGathering.interface";
import { RootState } from "../../../types/State.interface";
import { AppContext } from "../../Root";
import { AuthAction, AuthActionType, ComponentType } from "../../../services/sudirService";
import { ExpertGameResult, Sphere } from "./services/CrowdBirthday10.service";
import { selectIsUserReady } from "../../../store/reducers/profile";
import { LocalStorageKeys, LocalStorageService } from "../../../services/LocalStorage.service";
import { mapSize, verticalPinCorrection } from "./CrowdBirthday10.constants";
import { usePreloadImages } from "../../../hooks/usePreload";
import { mapLayerImages, preloadImages } from "./constants/images";
import { getGameData } from "./components/Games/ExpertGame/ExpertGame.utils";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import { selectAfterRegistrationFormOpen } from "../../../store/reducers/layout";
import { getMaxScale } from "./components/Map/Map.utils";
import { DEFAULT_SPHERE_GROUP_CODE } from "./components/Games/ExpertGame/constants";
import useAudio from "../../../hooks/useAudio";
import { replay } from "../../../utils/audio";

const pinHoverSound = require("../../../assets/birthday10/sounds/pin-hover.wav").default;
const pinQuestionHoverSound = require("../../../assets/birthday10/sounds/pin-question-hover.wav").default;
const completeSphereSound = require("../../../assets/birthday10/sounds/complete-sphere.wav").default;
const completeSphereLoopSound = require("../../../assets/birthday10/sounds/complete-sphere-loop.wav").default;
const fontanNoiseSound = require("../../../assets/birthday10/sounds/fontan-noise.wav").default;

const spheresLoopSound = ["dpir", "social", "railway", "digital"];
const spheresFallingSound = ["molodezh", "ecology", "parks", "improvement"];
const spheresFontanSound = ["molodezh", "culture"];

const expertGameBlocks = getGameData();
const defaultBlock = expertGameBlocks.find(({ id }) => id === DEFAULT_SPHERE_GROUP_CODE);

const CrowdBirthday10 = () => {
  const appContext = useContext(AppContext);
  const isPortrait = useMediaQuery({ query: "(orientation:portrait)" });
  const dispatch = useDispatch();
  const isUserReady = useSelector(selectIsUserReady);
  const afterRegistrationFormOpen = useSelector(selectAfterRegistrationFormOpen);
  const [isOpenWelcomeModal, setIsOpenWelcomeModal] = useState(true);
  const [canShowWelcomeModal, setCanShowWelcomeModal] = useState(false);
  const [isScreenLoaderReady, setIsScreenLoaderReady] = useState(false);
  const [headerIntance, setHeaderIntance] = useState<HeaderIntance>();
  const [mapInstance, setMapInstance] = useState<MapInstance>();
  const [expertGameInstance, setExpertGameInstance] = useState<ExportGameInstance>(null);
  const [selectedHeaderSphere, setSelectedHeaderSphere] = useState<Sphere>();

  const [objectsGatheringInstance, setObjectsGatheringInstance] = useState<ObjectsGatheringInstance>();
  const loggegIn = useSelector((state: RootState) => state.user.loggedIn);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const pinsRef = useRef<Record<number, Element>>({});
  const [ideasGameData, setIdeasGameData] = useState<ObjectsGatheringData>();
  const [expertGameResult, setExpertGameResult] = useState<ExpertGameResult>();
  const [isVisitedPage, setIsVisitedPage] = useState<boolean>(false);
  const loadingProgress = usePreloadImages(preloadImages);

  const pinHoverAudio = useAudio(pinHoverSound, { volume: 0.5 });
  const pinQuestionHoverAudio = useAudio(pinQuestionHoverSound, { volume: 0.35 });
  const completeSphereAudio = useAudio(completeSphereSound, { volume: 0.5 });
  const completeSphereLoopAudio = useAudio(completeSphereLoopSound, { volume: 0.5 });
  const fontanNoiseAudio = useAudio(fontanNoiseSound, { loop: true });

  const onPinHover = useCallback(() => {
    replay(pinHoverAudio);
  }, [pinHoverAudio]);
  const onPinQuestionHover = useCallback(() => {
    replay(pinQuestionHoverAudio);
  }, [pinQuestionHoverAudio]);

  const onCompleteSphere = useCallback(
    (sphere: Sphere) => {
      if (spheresLoopSound.includes(sphere.groupCode)) {
        replay(completeSphereLoopAudio);
      } else if (spheresFallingSound.includes(sphere.groupCode)) {
        replay(completeSphereAudio);
      }
    },
    [completeSphereAudio, completeSphereLoopAudio]
  );

  const completedShpereGroupCodes = useMemo(() => {
    if (expertGameResult && expertGameInstance) {
      return new Set(expertGameInstance.completedSphereGroupCodesRecord.values());
    }
    return new Set();
  }, [expertGameResult, expertGameInstance]);

  const mapObjects = useMemo<MapProps["objects"]>(
    () =>
      (expertGameInstance
        ? expertGameBlocks.map(({ id, bgSrc, bgCompletedSrc, pos, pinRelativePos, pinRelativeCompletedPos }) => {
            return {
              pos,
              children: (
                <Block
                  key={id}
                  groupCode={id}
                  isCompleted={completedShpereGroupCodes.has(id)}
                  bgSrc={bgSrc}
                  bgCompletedSrc={bgCompletedSrc}
                  pinPos={pinRelativePos}
                  pinCompletedPos={pinRelativeCompletedPos}
                  onPinClick={expertGameInstance.onNextSphere}
                  onPinMouseEnter={onPinQuestionHover}
                  pinFocus={selectedHeaderSphere?.groupCode === id}
                />
              ),
            };
          })
        : []
      ).concat(
        objectsGatheringInstance && !!ideasGameData?.groups
          ? objectsGatheringData
              .filter(({ num }) => !!ideasGameData.groups[num])
              .map(({ num, pos }) => ({
                pos,
                children: (
                  <Pin
                    ref={(el) => {
                      pinsRef.current[num] = el;
                    }}
                    key={num}
                    onClick={(e) => {
                      objectsGatheringInstance.chooseObject({
                        num,
                        target: e.currentTarget,
                      });
                    }}
                    onMouseEnter={onPinHover}
                  />
                ),
              }))
          : []
      ),
    [
      objectsGatheringInstance,
      expertGameInstance,
      completedShpereGroupCodes,
      ideasGameData?.groups,
      onPinHover,
      onPinQuestionHover,
      selectedHeaderSphere,
    ]
  );

  const onMapInit = (instance: MapInstance) => {
    // максимальный масштаб
    instance.translateTo(
      defaultBlock?.pinPos[0] || 0,
      (defaultBlock?.pinPos[1] || 0) - verticalPinCorrection,
      getMaxScale(instance),
      0,
      true
    );

    setMapInstance(instance);
  };

  const handleOnReady = () => {
    setIsScreenLoaderReady(true);
  };

  const handleOnStartExpertGame = () => {
    if (!isUserReady) {
      return appContext.sudirService.authWithActions([
        new AuthAction({
          component: ComponentType.BIRTHDAY10,
          type: AuthActionType.AUTH,
        }),
      ]);
    }

    if (expertGameResult?.status !== "FINISHED") {
      expertGameInstance?.onNextSphere();
    }
  };

  const handleOnStart = () => {
    if (expertGameResult?.status !== "FINISHED") {
      if (isVisitedPage) {
        expertGameInstance?.onNextSphere();
      } else {
        headerIntance?.openInstructionModal();
      }
    }

    setIsOpenWelcomeModal(false);
  };

  const onHeaderInit = (instance: HeaderIntance) => {
    setHeaderIntance(instance);
  };

  const onSetExpertGameSphere = (sphere: Sphere) => {
    if (sphere) {
      setIsOpenWelcomeModal(false);
    }
    if (spheresFontanSound.includes(sphere?.groupCode)) {
      replay(fontanNoiseAudio);
    } else {
      fontanNoiseAudio.pause();
    }
  };

  const onSkipInstruction = () => {
    if (expertGameResult?.status !== "FINISHED") {
      expertGameInstance?.onNextSphere();
    }
  };

  useEffect(() => {
    if (LocalStorageService.getData(LocalStorageKeys.BIRTHDAY10_PAGE_VISITED) === "1") {
      setIsVisitedPage(true);
    } else {
      LocalStorageService.saveData(LocalStorageKeys.BIRTHDAY10_PAGE_VISITED, "1");
    }
  }, []);

  useEffect(() => {
    if (expertGameResult && headerIntance) {
      headerIntance.setExpertGameProgress(expertGameResult.rightAnswerCount);
    }
  }, [expertGameResult, headerIntance]);
  // фикс стилей body
  useLayoutEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => document.body.classList.remove("overflow-hidden");
  }, []);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (
      isUserReady &&
      objectsGatheringInstance &&
      mapInstance &&
      !!ideasGameData?.groups &&
      !afterRegistrationFormOpen
    ) {
      const actions = appContext.sudirService.getActions(ComponentType.BIRTHDAY10_OBJECT_GATHERING);
      if (actions.length) {
        const groupIdeaFound = actions.find((act) => act.type === AuthActionType.GROUP_IDEA_FOUND);
        if (groupIdeaFound) {
          const { num, ideaId, x, y, scale } = groupIdeaFound.args;
          mapInstance.translate(x, y, scale);
          setIsOpenWelcomeModal(false);
          setTimeout(() =>
            objectsGatheringInstance.chooseObject({ num, selectedIdeaId: ideaId, target: pinsRef.current[num] })
          );
        }
      }
    }
  }, [isUserReady, objectsGatheringInstance, mapInstance, ideasGameData?.groups, afterRegistrationFormOpen]);

  useEffect(() => {
    if (headerIntance && user) {
      if (!loggegIn) {
        setCanShowWelcomeModal(true);
      } else if (isUserReady && expertGameInstance && expertGameResult && ideasGameData?.result) {
        const actions = appContext.sudirService.getActions(ComponentType.BIRTHDAY10);
        if (actions.length) {
          const joinGameAction = actions.find((act) => act.type === AuthActionType.JOIN_GAME);
          if (joinGameAction) {
            if (expertGameResult.status !== "FINISHED") {
              return;
            }
          }

          const authAction = actions.find((act) => act.type === AuthActionType.AUTH);
          if (authAction) {
            if (expertGameResult.status !== "FINISHED") {
              expertGameInstance?.onNextSphere();
              return;
            }
          }
        }
        setCanShowWelcomeModal(expertGameResult.status !== "FINISHED" || ideasGameData.result.status !== "FINISHED");
      }
    }
  }, [isUserReady, expertGameInstance, expertGameResult, headerIntance, user, loggegIn, ideasGameData?.result]);

  useEffect(() => {
    dispatch(widenPage());
    const wrapper: HTMLElement = document.querySelector(".wrapper");
    const initialPadding = wrapper.style.padding;
    wrapper.style.padding = "0";
    return () => {
      dispatch(lessenPage());
      wrapper.style.padding = initialPadding;
    };
  }, []);

  return (
    <>
      <AuthForm />
      <ToastProvider
        placement="top-center"
        components={{
          ToastContainer,
        }}
      >
        {isPortrait && <ScreenStub />}
        <Header
          mapInstance={mapInstance}
          onInit={onHeaderInit}
          startExpertGame={handleOnStartExpertGame}
          ideasGameResult={ideasGameData?.result}
          expertGameResult={expertGameResult}
          completedSphereGroupCodes={completedShpereGroupCodes}
          onSkipInstruction={onSkipInstruction}
          onSelectSphere={setSelectedHeaderSphere}
        />
        <div className={classNames({ "crowd-birthday-10": true, "crowd-birthday-10_mobile-device": isMobile })}>
          <ScreenLoader progress={loadingProgress} onReady={handleOnReady} />
          {isScreenLoaderReady && (
            <>
              <Map size={mapSize} layerSrc={mapLayerImages} objects={mapObjects} onMapInit={onMapInit} />
              {(!loggegIn || isUserReady) && (
                <WelcomeModal
                  onClose={() => {
                    setIsOpenWelcomeModal(false);
                  }}
                  onStart={handleOnStart}
                  isOpen={canShowWelcomeModal && isOpenWelcomeModal}
                />
              )}
              <ExpertGame
                mapInstance={mapInstance}
                onGameInit={setExpertGameInstance}
                onGameResult={setExpertGameResult}
                onSetSphere={onSetExpertGameSphere}
                onCorrectAnswer={onCompleteSphere}
              />
              <ObjectsGathering
                mapInstance={mapInstance}
                onGameInit={setObjectsGatheringInstance}
                onGameDataUpdate={setIdeasGameData}
              />
            </>
          )}
        </div>
      </ToastProvider>
    </>
  );
};

export default CrowdBirthday10;
