import { FC, forwardRef, useEffect, useMemo, useRef, useState } from "react";
import "./Welcome.scss";
import { declOfNum } from "../../../../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../types/State.interface";
import ProposeService from "../../../../../services/proposeService";
import { LandingInfo } from "../../DiscussionLanding.interface";
import DiscussionLandingService from "../../services/discussion-service";
import { phoneWidth, wideTabletWidth } from "../../../../../utils/constants/widthConstants";
import { useMediaQuery } from "react-responsive";
import Tooltip from "../../../../presentational/Controls/Tooltip/Tooltip";
import classNames from "classnames";
import useDiscussionLandingContext from "../../hooks/useDiscussionLandingContext";
import { PROJECT_CULTURE_ID } from "../../DiscussionLanding.constants";
import { ApiStatusCode } from "../../../../../types/Common.interface";

interface ExpertData {
  avatarUrl: string;
  text: string;
  fullName: string;
  position1: string;
  position2: string;
}

const Expert = forwardRef<HTMLDivElement>((_, ref) => {
  const discussionLandingContext = useDiscussionLandingContext();
  const [data, setData] = useState<ExpertData>();

  useEffect(() => {
    if (discussionLandingContext.projectCode === PROJECT_CULTURE_ID) {
      setData({
        avatarUrl: require("../../../../../assets/discussion-landing/expert-avatar.png").default,
        text: `Мы хотим развивать культуру Москвы, делая ее доступной, разнообразной и интересной для жителей и гостей
          столицы. Для этого создан новый формат взаимодействия с участниками «Города идей» — медиаплатформа «Город
          обсуждает стандарты культурного досуга». Она позволит горожанам активно участвовать в повышении качества
          оказываемых услуг и формировании культурной повестки столицы. Пользователи станут участниками масштабного
          проекта, первыми узнают об оценке экспертов отрасли и смогут внести свои предложения. Услуги, сервисы и
          мероприятия в области культуры получат новую жизнь именно благодаря мнениям москвичей.`,
        fullName: "А.А. Фурсин",
        position1: "Министр Правительства Москвы,",
        position2: "руководитель Департамента культуры города Москвы",
      });
    } else {
      DiscussionLandingService.getExpertQuote({ mediaPlatformId: discussionLandingContext.mediaPlatformId })
        .then(({ data, status, message }) => {
          if (status === ApiStatusCode.OK) {
            setData({
              avatarUrl: `/uploads/get/${data.photoId}`,
              text: data.text,
              fullName: data.expertFullName,
              position1: data.expertPosition1,
              position2: data.expertPosition2,
            });
          } else {
            console.error(message);
          }
        })
        .catch(console.error);
    }
  }, [discussionLandingContext]);

  return (
    <div className="discussion-landing-welcome__expert" ref={ref}>
      {data && (
        <>
          <div className="discussion-landing-welcome__expert__avatar">
            <img src={data.avatarUrl} alt="" />
          </div>
          <div className="discussion-landing-welcome__expert__quote">
            <div className="discussion-landing-welcome__expert__quote__text">{data.text}</div>
            <div className="discussion-landing-sign">
              <div className="discussion-landing-sign__name">{data.fullName}</div>
              <div className="discussion-landing-sign__position">
                {data.position1}
                <br />
                {data.position2}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
});

const Welcome: FC = () => {
  const discussionLandingContext = useDiscussionLandingContext();
  const expertRef = useRef<HTMLDivElement>();
  const currentProjects = useSelector((state: RootState) => state.projects.current);
  const completedProjects = useSelector((state: RootState) => state.projects.completed);
  const [count, setCount] = useState();
  const [participantCount, setParticipantCount] = useState<number>();
  const [landingInfo, setLandingInfo] = useState<Partial<LandingInfo>>();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${wideTabletWidth}px)` });
  const bgId = isPhone
    ? landingInfo?.mobileImageId
    : isTablet
    ? landingInfo?.tabletImageId
    : landingInfo?.desktopImageId;

  const shouldShowCounters = useMemo(() => !!(Number(count) && Number(participantCount)), [count, participantCount]);

  useEffect(() => {
    Promise.all([
      DiscussionLandingService.getInfo({ mediaPlatformId: discussionLandingContext.mediaPlatformId }),
      discussionLandingContext.projectCode
        ? discussionLandingContext.projectCode === PROJECT_CULTURE_ID
          ? DiscussionLandingService.getParticipantCount()
          : DiscussionLandingService.getSecondParticipantCount(discussionLandingContext.projectCode)
        : Promise.resolve({ data: 0 }),
    ])
      .then(([{ data }, { data: pCount }]) => {
        setLandingInfo(data || {});
        setParticipantCount(pCount);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [discussionLandingContext]);

  useEffect(() => {
    if (currentProjects && completedProjects) {
      const projectId = discussionLandingContext.projectCode
        ? [...currentProjects, ...completedProjects].find(({ id }) => id === discussionLandingContext.projectCode)?.id
        : undefined;
      if (projectId) {
        ProposeService.getGorodIdeasCount(projectId)
          .then(({ data }) => {
            setCount(data || 0);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [currentProjects, completedProjects, discussionLandingContext.projectCode]);

  const renderCounter = (count = 0, words, isVisible: boolean) => (
    <div
      className={classNames("discussion-landing-welcome__banner__counter", {
        "discussion-landing-welcome__banner__counter--visible": isVisible,
      })}
    >
      <div className="discussion-landing-welcome__banner__counter__num">
        {String(count)
          .split("")
          .map((num, i) => (
            <span key={i}>{num}</span>
          ))}
      </div>
      <div className="discussion-landing-welcome__banner__counter__txt">{declOfNum(count, words)}</div>
    </div>
  );

  const renderParticipantTooltip = (title: string) => (
    <Tooltip
      text={`Уникальные посетители медиаплатформы «Город обсуждает стандарты культурного досуга»`}
      idx="discussion-landing-welcome-participant-tip"
      place="bottom"
      className="discussion-landing-welcome__participant-tip"
      overridePosition={({ left, top }, _, __, refNode) => {
        let oLeft = left;
        if (!isTablet && expertRef.current) {
          oLeft = expertRef.current.offsetLeft + expertRef.current.offsetWidth - refNode.clientWidth;
        }
        return { left: Math.max(oLeft, 20), top };
      }}
    >
      {title}
    </Tooltip>
  );

  return (
    <section className="discussion-landing-welcome">
      <div
        className="discussion-landing-welcome__banner"
        style={{ backgroundImage: bgId ? `url(/uploads/get/${bgId})` : undefined }}
      >
        <div className="discussion-landing-welcome__banner__plate">
          {!!landingInfo && (
            <>
              <div className="discussion-landing-title">
                {landingInfo.name || "Город обсуждает стандарты культурного досуга"}
              </div>
              <div
                className="discussion-landing-welcome__banner__description"
                dangerouslySetInnerHTML={{
                  __html:
                    landingInfo.description ||
                    `Принимайте участие в создании нового стандарта культуры Москвы — предлагайте идеи, узнавайте мнение профессионалов отрасли, первыми читайте новости об акциях и проектах.`,
                }}
              />
              <div className="discussion-landing-welcome__banner__bottom">
                {renderCounter(count, ["идея подана", "идеи поданы", "идей подано"], shouldShowCounters)}
                {renderCounter(
                  participantCount,
                  [
                    renderParticipantTooltip("посетитель*"),
                    renderParticipantTooltip("посетителя*"),
                    renderParticipantTooltip("посетителей*"),
                  ],
                  shouldShowCounters
                )}
              </div>
            </>
          )}
        </div>
        {!isPhone && <Expert ref={expertRef} />}
      </div>
      {isPhone && <Expert ref={expertRef} />}
    </section>
  );
};

export default Welcome;
