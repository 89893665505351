import React from 'react';
import Tooltip from '../../../../presentational/Controls/Tooltip/Tooltip';
import { useMediaQuery } from 'react-responsive';
import { mediumTabletWidth, wideTabletWidth } from '../../../../../utils/constants/widthConstants';
import './InfoButton.scss';

interface InfoButtonProps {
  tooltipText: string;
  tooltipId: string;
}

const InfoButton: React.FC<InfoButtonProps> = ({ tooltipText, tooltipId }) => {

  const isMediumTablet = useMediaQuery({ query: `(max-width: ${mediumTabletWidth}px)` });
  const isWideTablet = useMediaQuery({ query: `(max-width: ${wideTabletWidth}px)` });

  const overridePosition = (position, currentEvent, currentTarget, node) => {
    const getLeft = () => {
      if (isMediumTablet) return 184;
      if (isWideTablet) return 395;
      return 428;
    };

    const getTop = () => {
      if (isMediumTablet) {
        return tooltipId === 'questionsTooltip' ? 20 : 38;
      }
      return tooltipId === 'questionsTooltip' ? 37 : 64;
    };

    return {
      left: getLeft(),
      top: getTop()
    };
  };

  return (
    <>
      <Tooltip
        text={tooltipText}
        idx={tooltipId}
        place="bottom"
        isOuter={true}
        outerClassName='birthday__tooltip'
        className='birthday__tooltip--inner'
        type='info'
        overridePosition={overridePosition}
      >
        <div
          data-for={tooltipId}
          className='info-button__container ui-icon-info'
        />
      </Tooltip>
    </>
  );
};

export default InfoButton;
