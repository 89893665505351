import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import Button from "../../presentational/Controls/Button/Button";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import PageDescription from "../../presentational/PageDescription/PageDescription";
import ProposeIdeaForm from "../../containers/Forms/ProposeIdeaForm/ProposeIdeaForm";
import { setAppColor } from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../App.interface";

import "./ProposeIdeaPage.scss";
import { AppContext } from "../../Root";
import { RootState } from "../../../types/State.interface";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../services/sudirService";
import GorodService, { GorodIdeaCategory, GorodIdeaSphere } from "../../../services/gorodService";
import SphereList from "../../containers/SphereList/SphereList";
import ProposeIdeaSubmittedForm from "../../containers/Forms/ProposeIdeaForm/ProposeIdeaSubmittedForm";
import { selectIsUserReady } from "../../../store/reducers/profile";
import Birthday10Banner from "./Birthday10Banner/Birthday10Banner";

const ProposeIdeaPage = (props) => {
  const appContext = useContext(AppContext);

  const loggedIn: boolean = useSelector((state: RootState) => state.user.loggedIn);
  const isUserReady = useSelector(selectIsUserReady);
  const spheres: GorodIdeaSphere[] = useSelector((state: RootState) => state.gorod.spheres);

  const [sphere, setSphere] = useState<GorodIdeaSphere>(null);
  const [category, setCategory] = useState<GorodIdeaCategory>(null);
  const [isProposeModalOpen, setIsProposeModalOpen] = useState<boolean>(false);
  const [isSubmittedProposeModalOpen, setIsSubmittedProposeModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.WHITE));
  }, []);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!spheres?.length || !isUserReady) return;

    const actions = appContext.sudirService.getActions(ComponentType.PROPOSE_IDEA_PAGE);
    if (actions.length) {
      const _openProposeModal = actions.find((act) => act.type === AuthActionType.OPEN_PROPOSE_MODAL);
      if (_openProposeModal) {
        const { code, categoryCode } = _openProposeModal.args;
        const sphere = GorodService.getSphereByCode(code, spheres);
        const category = sphere && GorodService.getCategoryByCode(sphere, categoryCode);
        openProposeModal(sphere, category);
      }
    }
  }, [spheres, isUserReady]);

  const openProposeModal = (sphere?: GorodIdeaSphere, category?: GorodIdeaCategory) => {
    if (!loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.PROPOSE_IDEA_PAGE,
        type: AuthActionType.OPEN_PROPOSE_MODAL,
        args: {
          code: sphere?.code || null,
          categoryCode: category?.code || null,
        },
      };

      const joinGameAction = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([joinGameAction]);
    }

    setSphere(sphere);
    setCategory(category);
    setIsProposeModalOpen(true);
  };

  const getPageDesription = () => {
    return `Вы можете участвовать в жизни города, предлагая свои идеи по его развитию, в любое удобное для вас время. Ознакомьтесь с <a class="boldBlue" target="blank" href="/ideaModeration">правилами обработки идей</a>, выберите интересующую вас тему из списка ниже и опишите ваше предложение. Лучшие идеи обязательно реализуют.`;
  };

  const onSelectCategory = (category: GorodIdeaCategory, sphere: GorodIdeaSphere) => {
    openProposeModal(sphere, category);
  };

  const onClose = () => {
    setSphere(null);
    setCategory(null);
    setIsProposeModalOpen(false);
  };
  const handleOpenPropose = (sphere) => {
    setSphere(sphere);
    setIsProposeModalOpen(true);
  };
  const onCloseSubmittedPropose = () => {
    setIsSubmittedProposeModalOpen(false);
  };
  // RENDER

  const title = "«Город идей» — Предложить идею";
  const description =
    "Участвуйте в жизни города, предлагайте свои идеи по его развитию, в любое удобное для вас время. Лучшие идеи обязательно реализуют.";
  return (
    <section className="propose-idea">
      <MetaDecorator title={title} description={description} opengraph={{ title, description }} />
      <PageTitle text={"Предложить идею"} />
      <PageDescription text={getPageDesription()} />

      <Birthday10Banner />

      <div className="propose-idea__btn-wrapper">
        <Button text="Предложить идею" type="filled" onClick={() => openProposeModal(null)} />
      </div>

      <SphereList onSelectCategory={onSelectCategory} />

      {isProposeModalOpen && (
        <ProposeIdeaForm
          sphere={sphere}
          category={category}
          isOpen={isProposeModalOpen}
          onClose={onClose}
          setIsSubmittedProposeModalOpen={setIsSubmittedProposeModalOpen}
        />
      )}
      {isSubmittedProposeModalOpen && (
        <ProposeIdeaSubmittedForm
          isOpen={isSubmittedProposeModalOpen}
          onClose={onCloseSubmittedPropose}
          handleOpenPropose={handleOpenPropose}
        />
      )}
    </section>
  );
};

export default ProposeIdeaPage;
