import { useEffect, useRef } from "react";

const useAudio = (src: string, { volume = 1, playbackRate = 1, loop = false } = {}) => {
  const sound = useRef<HTMLAudioElement>();

  useEffect(() => {
    sound.current = new Audio(src);
  }, [src]);

  useEffect(() => {
    sound.current.playbackRate = playbackRate;
  }, [playbackRate]);

  useEffect(() => {
    sound.current.volume = volume;
  }, [volume]);

  useEffect(() => {
    sound.current.loop = loop;
  }, [loop]);

  return sound.current;
};

export default useAudio;
