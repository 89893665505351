import { FC, useContext, useEffect, useRef, useState } from "react";
import "./ExpertGame.scss";

import SphereCard from "./SphereCard/SphereCard";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../../../utils/constants/widthConstants";
import SphereQuizCard from "./SphereQuizCard/SphereQuizCard";
import CorrectAnswerModal from "../../Modals/CorrectAnswerModal/CorrectAnswerModal";
import IncorrectAnswerModal from "../../Modals/IncorrectAnswerModal/IncorrectAnswerModal";
import CrowdBirthday10Service, { ExpertGameResult, Sphere } from "../../../services/CrowdBirthday10.service";
import { ApiStatusCode } from "../../../../../../types/Common.interface";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../types/State.interface";
import { AppContext } from "../../../../../Root";
import { AuthAction, AuthActionType, ComponentType } from "../../../../../../services/sudirService";
import { MapInstance } from "../../Map/Map.interface";
import CompletedSphereModal from "../../Modals/CompletedSphereModal/CompletedSphereModal";
import WinModal from "../../Modals/WinModal/WinModal";
import { useToasts } from "react-toast-notifications";
import { animationDuration } from "../../Map/Map.constants";
import ProposeIdeaForm from "../../../../../containers/Forms/ProposeIdeaForm/ProposeIdeaForm";
import ProposeIdeaSubmittedForm from "../../../../../containers/Forms/ProposeIdeaForm/ProposeIdeaSubmittedForm";
import { GorodIdeaSphere } from "../../../../../../services/gorodService";
import { isIOS } from "react-device-detect";
import { DEFAULT_SPHERE_GROUP_CODE } from "./constants";
import { getGameData } from "./ExpertGame.utils";
import { selectIsUserReady } from "../../../../../../store/reducers/profile";
import { selectAfterRegistrationFormOpen } from "../../../../../../store/reducers/layout";
import { verticalPinCorrection } from "../../../CrowdBirthday10.constants";
import useUpdateEffect from "../../../../../../hooks/useUpdateEffect";

export interface SphereQuestion {
  question: string;
  questionDescription: string;
}
export interface ExportGameInstance {
  onNextSphere: (id?: string) => void;
  completedSphereGroupCodesRecord: Set<string>;
}

interface ExpertGameProps {
  mapInstance: MapInstance;
  onGameInit: (exportGame: ExportGameInstance) => void;
  onGameResult: (data: ExpertGameResult) => void;
  onSetSphere?: (sphere: Sphere) => void;
  onCorrectAnswer?: (sphere: Sphere) => void;
}

const expertGameBlocks = getGameData();

const ExpertGame: FC<ExpertGameProps> = ({ onGameInit, onGameResult, mapInstance, onSetSphere, onCorrectAnswer }) => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isUserReady = useSelector(selectIsUserReady);
  const afterRegistrationFormOpen = useSelector(selectAfterRegistrationFormOpen);
  const appContext = useContext(AppContext);
  const { addToast } = useToasts();
  const spheresRef = useRef<Array<Sphere>>(null);
  const mapInstanceRef = useRef<MapInstance>(null);
  const gameResultRef = useRef<ExpertGameResult>(null);
  const completedSphereGroupCodesRecordRef = useRef<Set<string>>(new Set());
  const incompletedSphereIdsRecordRef = useRef<Set<string>>(new Set());
  const shpereIdsRecordByGroupCodeRef = useRef<Map<string, string>>(new Map());
  const needSaveStateAfterCloseProposeRef = useRef(false);

  const gorodIdeaSpheres: GorodIdeaSphere[] = useSelector((state: RootState) => state.gorod.spheres);
  const [selectedSphere, setSelectedSphere] = useState<GorodIdeaSphere>(null);

  const [spheres, setSpheres] = useState<Array<Sphere>>();
  const [sphere, setSphere] = useState<Sphere>(null);
  const [isSphereByDefault, setIsSphereByDefault] = useState(false);
  const [isCompletedSphere, setIsCompletedSphere] = useState(false);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState<boolean | null>(null);
  const [startQuiz, setStartQuiz] = useState(false);
  const [answer, setAnswer] = useState<boolean | null>(null);
  const [gameResult, setGameResult] = useState<ExpertGameResult>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isGameFinished, setIsGameFinished] = useState(false);
  const [isOpenWinModal, setIsOpenWinModal] = useState(false);
  const [isOpenProposeIdeaModal, setIsOpenProposeIdeaModal] = useState(false);
  const [isSubmittedProposeModalOpen, setIsSubmittedProposeModalOpen] = useState(false);

  const setCurrentSphere = (sphere: Sphere, byDefault = false) => {
    setSphere(sphere);
    setIsSphereByDefault(byDefault);
  };

  useEffect(() => {
    if (spheres) {
      spheresRef.current = spheres;
    }
  }, [spheres]);

  useEffect(() => {
    if (sphere && gorodIdeaSpheres) {
      const selectedgGrodIdeaSphere = gorodIdeaSpheres.find(
        (gorodIdeaSphere) => gorodIdeaSphere.code === sphere.groupCode
      );
      setSelectedSphere(selectedgGrodIdeaSphere ?? null);
    }
  }, [sphere, gorodIdeaSpheres]);

  useEffect(() => {
    onSetSphere?.(sphere);
  }, [sphere]);

  useEffect(() => {
    if (gameResult) {
      gameResultRef.current = gameResult;
    }
  }, [gameResult]);

  useEffect(() => {
    if (mapInstance) {
      mapInstanceRef.current = mapInstance;
    }
  }, [mapInstance]);

  useEffect(() => {
    if (gameResult) {
      const currentIncompletedSphereIdsRecord = incompletedSphereIdsRecordRef.current;

      gameResult.answers.forEach((answer) => {
        if (answer.correct) {
          currentIncompletedSphereIdsRecord.delete(answer.sphereId);

          const groupCode = shpereIdsRecordByGroupCodeRef.current.get(answer.sphereId);

          if (groupCode) {
            completedSphereGroupCodesRecordRef.current.add(groupCode);
          }
        }
      });

      if (answer !== null) {
        setIsCorrectAnswer(!currentIncompletedSphereIdsRecord.has(sphere.id));
      }

      onGameResult(gameResult);
    }
  }, [gameResult]);

  useEffect(() => {
    if (sphere && gameResult && answer === null) {
      const hasCorrectAnswer = gameResult.answers.find((answer) => answer.sphereId === sphere.id && answer.correct);
      if (hasCorrectAnswer) {
        setIsCompletedSphere(true);
      }
    }
  }, [sphere, gameResult]);

  useEffect(() => {
    if (sphere && answer !== null && isCorrectAnswer) {
      onCorrectAnswer?.(sphere);
    }
  }, [answer, isCorrectAnswer, sphere]);

  // Initialization
  useEffect(() => {
    onGameInit({
      onNextSphere,
      completedSphereGroupCodesRecord: completedSphereGroupCodesRecordRef.current,
    });

    CrowdBirthday10Service.getSpheres()
      .then((data) => {
        data.sort((a, b) => a.number - b.number);

        setSpheres(data);

        data.forEach((sphere) => {
          incompletedSphereIdsRecordRef.current.add(sphere.id);
          shpereIdsRecordByGroupCodeRef.current.set(sphere.id, sphere.groupCode);
        });
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    incompletedSphereIdsRecordRef.current.clear();
    shpereIdsRecordByGroupCodeRef.current.clear();

    clearState();
  }, [isUserReady]);

  // After login
  useEffect(() => {
    if (!gameResult || !isUserReady || !mapInstance || afterRegistrationFormOpen || !spheres || !gorodIdeaSpheres) {
      return;
    }

    const actions = appContext.sudirService.getActions(ComponentType.BIRTHDAY10_EXPERT_GAME);
    if (actions.length) {
      const joinGameAction = actions.find((act) => act.type === AuthActionType.JOIN_GAME);
      if (joinGameAction) {
        const { groupCode, x, y, scale } = joinGameAction.args;
        mapInstance.translate(x, y, scale);
        if (!isGameFinished) {
          onNextSphere(groupCode);
          setStartQuiz(true);
        }
      }

      const openProposeModal = actions.find((act) => act.type === AuthActionType.OPEN_PROPOSE_MODAL);
      if (openProposeModal) {
        const { storedSphereCode, needSaveStateAfterClosePropose, x, y, scale } = openProposeModal.args;
        const storedSphere = spheres.find(({ groupCode }) => groupCode === storedSphereCode);
        const selectedGorodIdeaSphere = gorodIdeaSpheres.find(({ code }) => code === storedSphereCode);
        mapInstance.translate(x, y, scale);
        setSelectedSphere(selectedGorodIdeaSphere);
        setCurrentSphere(storedSphere);
        onProposeIdea(needSaveStateAfterClosePropose);
      }
    }
  }, [afterRegistrationFormOpen, gameResult, isUserReady, mapInstance, spheres, gorodIdeaSpheres]);

  useEffect(() => {
    if (isUserReady && spheres?.length) {
      setIsLoading(true);

      CrowdBirthday10Service.getExpertGameResult()
        .then((response) => {
          const { status, data, message } = response;
          if (status === ApiStatusCode.OK) {
            if (data.status === "FINISHED") {
              setIsGameFinished(true);
            }
            setGameResult(data);
          } else {
            console.error(message);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }, [isUserReady, spheres?.length]);

  useUpdateEffect(() => {
    if (!isOpenProposeIdeaModal && !isSubmittedProposeModalOpen) {
      if (!needSaveStateAfterCloseProposeRef.current) {
        clearState();
      } else {
        needSaveStateAfterCloseProposeRef.current = false;
      }
    }
  }, [isOpenProposeIdeaModal, isSubmittedProposeModalOpen]);

  const onProposeIdea = (needSaveStateAfterClosePropose = needSaveStateAfterCloseProposeRef.current) => {
    if (!isUserReady) {
      return appContext.sudirService.authWithActions([
        new AuthAction({
          component: ComponentType.BIRTHDAY10_EXPERT_GAME,
          type: AuthActionType.OPEN_PROPOSE_MODAL,
          args: {
            storedSphereCode: sphere.groupCode,
            needSaveStateAfterClosePropose,
            ...mapInstance?.getTranslate(),
          },
        }),
      ]);
    }
    needSaveStateAfterCloseProposeRef.current = needSaveStateAfterClosePropose;
    setIsOpenProposeIdeaModal(true);
  };

  const moveToSphere = (posX: number, posY: number) => {
    if (mapInstanceRef?.current) {
      mapInstanceRef.current.translateTo(
        posX,
        posY - verticalPinCorrection,
        mapInstanceRef.current.getTranslate().scale,
        isIOS ? 0 : animationDuration,
        true
      );
    }
  };

  const onNextSphere = (groupCode?: string) => {
    clearState();

    const code = setSureSphereGroupCode(groupCode);
    const sphereByDefault = code !== groupCode;

    const currentSpheres = spheresRef.current;
    if (currentSpheres?.length) {
      const currentSphere = currentSpheres.find((searchedSphere) => searchedSphere.groupCode === code);
      const sphereMapView = expertGameBlocks.find((expertGameBlock) => expertGameBlock.id === code);

      if (currentSphere) {
        if (sphereByDefault) {
          addToast(
            <>
              Вы переходите к сфере <span dangerouslySetInnerHTML={{ __html: currentSphere.groupName }} />
            </>,
            {
              id: "crowd-birthday10-map",
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000,
              onDismiss: () => {
                setCurrentSphere(currentSphere, true);
              },
            }
          );
          setTimeout(() => {
            moveToSphere(sphereMapView.pinPos[0], sphereMapView.pinPos[1]);
          }, 400);
        } else {
          setCurrentSphere(currentSphere);
          moveToSphere(sphereMapView.pinPos[0], sphereMapView.pinPos[1]);
        }
      }
    }
  };

  const setSureSphereGroupCode = (groupCode?: string): string => {
    const code = typeof groupCode !== "string" ? null : groupCode;

    if (code) return code;

    if (gameResultRef.current) {
      const currentSpheres = spheresRef.current;
      const currentIncompletedSphereIdsRecord = incompletedSphereIdsRecordRef.current;

      const incompletedSpheres = currentSpheres?.filter((currentSphere) =>
        currentIncompletedSphereIdsRecord.has(currentSphere.id)
      );

      if (incompletedSpheres?.length) {
        const fromSphere = sphere
          ? sphere
          : currentSpheres.findLast((currentSphere) => !currentIncompletedSphereIdsRecord.has(currentSphere.id));
        if (fromSphere) {
          const nextShpere = incompletedSpheres.find(
            (incompletedSphere) => incompletedSphere.number > fromSphere.number
          );

          return nextShpere ? nextShpere.groupCode : incompletedSpheres[0].groupCode;
        }

        return incompletedSpheres[0].groupCode;
      }
      return DEFAULT_SPHERE_GROUP_CODE;
    }

    if (!isUserReady) return DEFAULT_SPHERE_GROUP_CODE;
  };

  const handleOnStart = () => {
    if (!isUserReady) {
      return appContext.sudirService.authWithActions([
        new AuthAction({
          component: ComponentType.BIRTHDAY10_EXPERT_GAME,
          type: AuthActionType.JOIN_GAME,
          args: { groupCode: isSphereByDefault ? null : sphere.groupCode, ...mapInstance?.getTranslate() },
        }),
        new AuthAction({
          component: ComponentType.BIRTHDAY10,
          type: AuthActionType.JOIN_GAME,
        }),
      ]);
    }
    if (!isGameFinished) {
      setStartQuiz(true);
    }
  };

  const handleOnAnswer = (answer: boolean) => {
    setAnswer(answer);
    setIsLoading(true);

    CrowdBirthday10Service.saveExpertGame({ sphereId: sphere.id, answer })
      .then((response) => {
        const { data, status, message } = response;
        if (status === ApiStatusCode.OK) {
          if (data.status === "FINISHED") {
            setIsGameFinished(true);
            setIsOpenWinModal(true);
          }
          setGameResult(data);
        } else {
          console.error(message);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setStartQuiz(false);
        setIsLoading(false);
      });
  };

  const handleOnRetry = () => {
    setAnswer(null);
    setStartQuiz(true);
  };

  const onProposeClick = (sphere: Sphere) => {
    const gorodIdeaSphere = gorodIdeaSpheres.find(({ code }) => code === sphere.groupCode);
    setSelectedSphere(gorodIdeaSphere);
    onProposeIdea(true);
  };

  const clearState = () => {
    setCurrentSphere(null);
    setStartQuiz(false);
    setAnswer(null);
    setIsCompletedSphere(false);
    setIsCorrectAnswer(null);
  };

  if (isOpenProposeIdeaModal) {
    return (
      <ProposeIdeaForm
        isOpen
        onClose={() => {
          setIsOpenProposeIdeaModal(false);
        }}
        sphere={selectedSphere}
        setIsSubmittedProposeModalOpen={setIsSubmittedProposeModalOpen}
      />
    );
  }

  if (isSubmittedProposeModalOpen) {
    return (
      <ProposeIdeaSubmittedForm
        isOpen
        onClose={() => setIsSubmittedProposeModalOpen(false)}
        handleOpenPropose={(nextSphere) => {
          onProposeIdea();
          setSelectedSphere(nextSphere);
        }}
      />
    );
  }

  if (isOpenWinModal) {
    return <WinModal onClose={() => setIsOpenWinModal(false)} position={!isPhone ? "right" : null} />;
  }

  if (isCompletedSphere) {
    return (
      <CompletedSphereModal
        text={sphere.descriptionAfterFinish}
        onProposeIdea={onProposeIdea}
        onNext={clearState}
        onClose={clearState}
        position={!isPhone ? "right" : null}
        isGameFinished={isGameFinished}
      />
    );
  }

  if (!sphere) {
    return <></>;
  }

  return (
    <>
      <SphereCard
        sphere={sphere}
        onStart={handleOnStart}
        onClose={clearState}
        onProposeClick={onProposeClick}
        isGameFinished={isGameFinished}
        isOpen={!startQuiz && answer === null}
        position={!isPhone ? "left" : null}
      />
      <SphereQuizCard
        onAnwser={handleOnAnswer}
        question={sphere.question}
        questionDescription={sphere.questionDescription}
        isDisabled={isLoading}
        isOpen={startQuiz}
        position={!isPhone ? "right" : null}
        onClose={clearState}
      />
      {answer !== null && !startQuiz && !isGameFinished && (
        <CorrectAnswerModal
          message={sphere.correctAnswerMessage}
          isOpen={isCorrectAnswer}
          onNext={clearState}
          onProposeIdea={onProposeIdea}
          onClose={clearState}
          position={!isPhone ? "right" : null}
        />
      )}
      {answer !== null && isCorrectAnswer !== null && !startQuiz && !isGameFinished && (
        <IncorrectAnswerModal
          message={sphere.notCorrectAnswerMessage}
          isOpen={!isCorrectAnswer}
          onNext={onNextSphere}
          onRetry={handleOnRetry}
          onClose={clearState}
          position={!isPhone ? "right" : null}
        />
      )}
    </>
  );
};
export default ExpertGame;
