import { FC, useEffect, useRef } from "react";
import { PinQuestionProps } from "./PinQuestion.interface";
import "./PinQuestion.scss";
import classNames from "classnames";

const PinQuestion: FC<PinQuestionProps> = ({ onClick, onMouseEnter, isCompleted = false, focus = false }) => {
  const pinRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (focus) {
      let innerTid;
      const tid = setTimeout(() => {
        pinRef.current.focus({ preventScroll: true });
        innerTid = setTimeout(() => {
          pinRef.current.blur();
        }, 2000);
      }, 300);

      return () => {
        clearTimeout(tid);
        clearTimeout(innerTid);
      };
    }
  }, [focus]);

  return (
    <div
      className={classNames({
        "crowd-birthday-10-pin-question": true,
        "crowd-birthday-10-pin-question_completed": isCompleted,
      })}
    >
      <div
        ref={pinRef}
        tabIndex={0}
        className="crowd-birthday-10-pin-question__bubble"
        onClick={onClick}
        onPointerEnter={onMouseEnter}
      >
        <div className="crowd-birthday-10-pin-question__bubble__icon" />
      </div>
    </div>
  );
};

export default PinQuestion;
