export function queryBuilder(query) {
  var queryBuilder = "?";

  Object.keys(query).forEach((key) => {
    queryBuilder += `${key}=${typeof query[key] === "string" ? query[key] : JSON.stringify(query[key])}&`;
  });

  return queryBuilder;
}

export const linkify = (text: string, isBlank = false) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, `<a href="$1" ${isBlank ? "target='_blank'" : ""}>$1</a>`);
};
