import { useRef, useState } from "react";
import { useRefsEffect } from "./useRefsEffect";

export function usePreloadImages(srcs: string[], canStartPreload: boolean = true) {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const totalRef = useRef(srcs.length);
  const loadedRef = useRef(0);

  useRefsEffect(
    ({ srcs }) => {
      if (!canStartPreload) return;

      srcs.forEach((src) => {
        const image = new Image();

        const updateLoadingProgress = () => {
          loadedRef.current++;
          setLoadingProgress((loadedRef.current / totalRef.current) * 100);
        };

        image.onload = updateLoadingProgress;
        image.onerror = updateLoadingProgress;
        image.src = src;
      });
    },
    [canStartPreload],
    { srcs }
  );

  return loadingProgress;
}
