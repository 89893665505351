import { isMobile } from "react-device-detect";

export const objectsGatheringData: { num: number; pos: [number, number] }[] = isMobile
  ? [
      { num: 1, pos: [84, 1202.5] },
      { num: 2, pos: [815, 698] },
      { num: 3, pos: [245.5, 1294.5] },
      { num: 4, pos: [1148, 882] },
      { num: 5, pos: [1806, 584] },
      { num: 6, pos: [214, 1688] },
      { num: 7, pos: [792, 1350] },
      { num: 8, pos: [1662, 840] },
      { num: 9, pos: [1958, 670] },
      { num: 10, pos: [2196, 530] },
      { num: 11, pos: [2680.5, 417] },
      { num: 12, pos: [3150, 710] },
      { num: 13, pos: [2848, 886] },
      { num: 14, pos: [2582, 1046] },
      { num: 15, pos: [2334, 894] },
      { num: 16, pos: [2078, 1060] },
      { num: 17, pos: [1524, 1110] },
      { num: 18, pos: [1802, 1262] },
      { num: 19, pos: [2292, 1208] },
      { num: 20, pos: [956, 1448] },
      { num: 21, pos: [1170, 1570] },
      { num: 22, pos: [1426.5, 1721] },
      { num: 23, pos: [379.5, 1792.5] },
      { num: 24, pos: [1102, 1902] },
      { num: 25, pos: [839.5, 2058.5] },
      { num: 26, pos: [395.5, 2486.5] },
      { num: 27, pos: [745.5, 2692.5] },
      { num: 28, pos: [882, 2768] },
      { num: 29, pos: [1278, 2530] },
      { num: 30, pos: [1206.5, 2271] },
      { num: 31, pos: [1466.5, 2427] },
      { num: 32, pos: [1848, 2208] },
      { num: 33, pos: [1690, 1866] },
      { num: 34, pos: [2057.5, 2083] },
      { num: 35, pos: [2334, 1920] },
      { num: 36, pos: [2304, 1546] },
      { num: 37, pos: [2942.5, 1577.5] },
      { num: 38, pos: [3182, 1384] },
      { num: 39, pos: [3030, 964] },
      { num: 40, pos: [3190, 1056] },
      { num: 41, pos: [3242, 1748] },
      { num: 42, pos: [3162, 2048] },
      { num: 43, pos: [2580.5, 2384] },
      { num: 44, pos: [1680, 2550] },
      { num: 45, pos: [2022, 2742] },
    ]
  : [
      { num: 1, pos: [168, 2405] },
      { num: 2, pos: [1630, 1396] },
      { num: 3, pos: [491, 2589] },
      { num: 4, pos: [2296, 1764] },
      { num: 5, pos: [3612, 1168] },
      { num: 6, pos: [428, 3376] },
      { num: 7, pos: [1584, 2700] },
      { num: 8, pos: [3324, 1680] },
      { num: 9, pos: [3916, 1340] },
      { num: 10, pos: [4392, 1060] },
      { num: 11, pos: [5361, 834] },
      { num: 12, pos: [6300, 1420] },
      { num: 13, pos: [5696, 1772] },
      { num: 14, pos: [5164, 2092] },
      { num: 15, pos: [4668, 1788] },
      { num: 16, pos: [4156, 2120] },
      { num: 17, pos: [3048, 2220] },
      { num: 18, pos: [3604, 2524] },
      { num: 19, pos: [4584, 2416] },
      { num: 20, pos: [1912, 2896] },
      { num: 21, pos: [2340, 3140] },
      { num: 22, pos: [2853, 3442] },
      { num: 23, pos: [759, 3585] },
      { num: 24, pos: [2204, 3804] },
      { num: 25, pos: [1679, 4117] },
      { num: 26, pos: [791, 4973] },
      { num: 27, pos: [1491, 5385] },
      { num: 28, pos: [1764, 5536] },
      { num: 29, pos: [2556, 5060] },
      { num: 30, pos: [2413, 4542] },
      { num: 31, pos: [2933, 4854] },
      { num: 32, pos: [3696, 4416] },
      { num: 33, pos: [3380, 3732] },
      { num: 34, pos: [4115, 4166] },
      { num: 35, pos: [4668, 3840] },
      { num: 36, pos: [4608, 3092] },
      { num: 37, pos: [5885, 3155] },
      { num: 38, pos: [6364, 2768] },
      { num: 39, pos: [6060, 1928] },
      { num: 40, pos: [6380, 2112] },
      { num: 41, pos: [6484, 3496] },
      { num: 42, pos: [6324, 4096] },
      { num: 43, pos: [5161, 4768] },
      { num: 44, pos: [3360, 5100] },
      { num: 45, pos: [4044, 5484] },
    ];
