import { FC, useEffect, useState } from "react";
import "./Rating.scss";

// Import Swiper core, modules, and components
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

import RatingCard from "./RatingCard/RatingCard";
import DiscussionLandingService from "../../services/discussion-service";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { displayError } from "../../../../../utils";
import DefaultPagination from "../../../../presentational/Controls/Pagination/Pagination";
import useDiscussionLandingContext from "../../hooks/useDiscussionLandingContext";

export interface IdeaCategory {
  id: string;
  published: boolean;
  name: string;
  link: string;
  imageId: string;
  created: string;
  className?: string;
}

export interface RatingProps {
  onLoadEnd?: (data) => void;
}

//переписать с исп lazyLoading, если много направлений
const Rating: FC<RatingProps> = ({ onLoadEnd }) => {
  const discussionLandingContext = useDiscussionLandingContext();
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  SwiperCore.use([Autoplay, Navigation, Pagination]);

  const categoryColors = ["blue", "purple", "orange", "green", "pink", "light-blue", "red"];

  useEffect(() => {
    fetchData();
  }, [discussionLandingContext.mediaPlatformId]);

  const fetchData = async () => {
    setLoading(true);

    let onLoadEndData = [];

    try {
      const response = await DiscussionLandingService.getIdeasRating({
        mediaPlatformId: discussionLandingContext.mediaPlatformId,
      });
      if (response.status === ApiStatusCode.OK) {
        setCategories(response?.data);
        onLoadEndData = response.data;
      } else {
        displayError("Ошибка при получении рейтинга идей", response.message);
      }
    } catch (error) {
      displayError("Rating", "Ошибка при получении рейтинга идей");
    } finally {
      onLoadEnd(onLoadEndData);
      setLoading(false);
    }
  };

  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    setCurrentPage(activeIndex);
  };

  if (!categories?.length) return <></>;

  return (
    categories &&
    !isLoading &&
    categories.length > 0 && (
      <section className="discussion-landing-rating" id="Rating">
        <div className="discussion-landing-title">Рейтинг идей</div>
        <div className="discussion-landing-text">
          Каждую неделю эксперты проекта отбирают самые интересные идеи москвичей, посвященные новому стандарту
          культурного досуга в столице, а также отправленные в раздел «Предложить идею». Пользователи со стандартной и
          полной учетной записью mos.ru, чьи идеи будут отобраны экспертами для рейтинга, будут отмечены в номинациях
          проектов и после их завершения получат{" "}
          <a href="/pointsRules" target="blank">
            3000 баллов.
          </a>
        </div>

        <Swiper
          onSwiper={setSwiperInstance}
          className={"rating__slider"}
          onInit={handleSlideChange}
          onSlideChangeTransitionEnd={handleSlideChange}
          slidesPerView={1}
          effect="fade"
          navigation={{
            nextEl: ".cr-pagination__arrow-right",
            prevEl: ".cr-pagination__arrow-left",
          }}
          spaceBetween={40}
          loop={false}
        >
          {categories?.map((category, index) => {
            const { id, name, link, imageId, created, published } = category;
            const colorIndex = index % categoryColors.length;
            const colorClass = categoryColors[colorIndex];
            return (
              <SwiperSlide key={id}>
                <RatingCard
                  className={colorClass}
                  id={id}
                  name={name}
                  link={link}
                  imageId={imageId}
                  created={created}
                  published={published}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <DefaultPagination
          loop={false}
          onPageChange={(page) => {
            swiperInstance.slideTo(page);
          }}
          pagesRange={2}
          totalPages={categories.length}
          currentPage={currentPage}
        />
      </section>
    )
  );
};

export default Rating;
