import React from "react";
import Platform from "../../../../services/platform";
import { Link, useHistory } from "react-router-dom";

import BookViewBase from "./BookViewBase";

class FullPage extends BookViewBase {
  fullPageRef: any;
  bookView: any;
  page: number = 0;

  constructor(bookView) {
    super();

    this.bookView = bookView;
    this.fullPageRef = React.createRef();
  }

  getItems() {
    return this.isPhone() ? this.bookView.props.mobileImages : this.bookView.props.desktopImages;
  }

  isPhone() {
    return window.innerWidth <= 500;
  }

  renderSlides() {
    if (!this.getItems()) return;

    return this.getItems().map((img, idx) => (
      <div
        key={idx}
        className={`full-page__slide slide-${idx + 1}`}
        style={{
          backgroundColor: "#" + img.bgColor || "",
          height: !this.isPhone() ? window.innerHeight + "px" : "unset",
        }}
      >
        <img src={`/uploads/get/${img.id}`} />
      </div>
    ));
  }

  renderLastSlide() {
    return (
      <div className="full-page__slide slide-last" style={{ height: window.innerHeight + "px" }}>
        <h2 className="slide-last__title">Спасибо за внимание!</h2>

        <div className="slide-last__links">
          {/* <Link
            className="slide-last__link"
            to={`/plan?id=${this.bookView.props.project}`}
          >
            Принятые идеи
          </Link>

          <Link
            className="slide-last__link"
            to={`/ideas?id=${this.bookView.props.project}`}
          >
            Реализованные идеи
          </Link> */}

          <Link className="slide-last__link" to="/archive">
            Другие проекты
          </Link>
          {this.bookView.props.hasSubProjects && (
            <Link className="slide-last__link" to={`/archive?id=${this.bookView.props.project}`}>
              Назад
            </Link>
          )}
        </div>
      </div>
    );
  }

  get canSlidePrev() {
    return this.page !== 0;
  }

  get canSlideNext() {
    return this.page < this.bookView.props.desktopImages.length;
  }

  getTotalPagesNum() {
    return this.bookView.props.desktopImages.length + 1;
  }

  swipe() {
    this.fullPageRef.current.style.transform = `translate3d(0px, ${this.page * -window.innerHeight}px, 0px)`;
  }

  swipeDown() {
    if (this.isPhone()) return;

    this.page = Math.min(this.page + 1, this.getTotalPagesNum() - 1);
    this.swipe();
    this.bookView.forceUpdate();
  }

  swipeUp() {
    if (this.isPhone()) return;

    this.page = Math.max(this.page - 1, 0);
    this.swipe();
    this.bookView.forceUpdate();
  }

  render() {
    return (
      <div className="full-page" ref={this.fullPageRef}>
        {this.renderSlides()}
        {!this.isPhone() && this.renderLastSlide()}
      </div>
    );
  }
}

export default FullPage;
