import { FC, useEffect, useRef } from "react";
import "./ScreenLoader.scss";

interface ScreenLoaderProps {
  progress: number;
}

const ScreenLoader: FC<ScreenLoaderProps> = ({ progress }) => {
  const barRef = useRef<HTMLDivElement>();

  useEffect(() => {
    barRef.current.style.transform = `translateX(${progress * 100}%)`;
  }, [progress]);

  return (
    <div className="discussion-landing-screen-loader">
      <div className="discussion-landing-screen-loader__logo" />
      <div className="discussion-landing-screen-loader__progress">
        <div className="discussion-landing-screen-loader__progress-bar" ref={barRef}></div>
      </div>
    </div>
  );
};

export default ScreenLoader;
