import { DiscussionLandingConfig } from "./DiscussionLanding.interface";

export const PROJECT_CULTURE_ID = "mmr";

export const discussionLandingConfigs: Array<DiscussionLandingConfig> = [
  {
    mediaPlatformId: 1,
    url: "/culture",
    projectCode: PROJECT_CULTURE_ID,
  },
  {
    mediaPlatformId: 2,
    url: "/newlanding",
    projectCode: "newlandingmp",
  },
];

export const discussionLandingRepostUrl = "/culture";

export enum RegistrationStatus {
  WAIT = "WAIT",
  NOW = "NOW",
  FINISHED = "FINISHED",
  REGISTRATION_FINISHED = "REGISTRATION_FINISHED",
  REGISTERED = "REGISTERED",
}

export const RegistrationStatusToTextMap = {
  [RegistrationStatus.WAIT]: "Регистрация",
  [RegistrationStatus.NOW]: "Регистрация",
  [RegistrationStatus.REGISTERED]: "Вы зарегистрированы",
  [RegistrationStatus.FINISHED]: "Завершено",
  [RegistrationStatus.REGISTRATION_FINISHED]: "Регистрация закрыта",
};

export enum DiscussionStatus {
  WAITING = "WAITING",
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}

export const ogBaseTitle = "Платформа Правительства Москвы «Город идей»";
export const ogVideoTitle =
  "Посмотрите новое видео от «Города идей» на медиаплатформе «Город обсуждает стандарты культурного досуга»!";
