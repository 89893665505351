import React from 'react';
import { declOfNum } from '../../../../../utils';
import { RootState } from '../../../../../types/State.interface';
import { useSelector } from 'react-redux';
import './Stats.scss'

const Stats: React.FC = () => {
  const totalStatistics = useSelector((state: RootState) => state.totalStatistics.stats);

  if (!totalStatistics) {
    return null;
  }

  return (
    <div className='stats'>
      <span className="stats__count">
        {totalStatistics.realizedIdeaCount
          ? totalStatistics.realizedIdeaCount?.toLocaleString("ru-RU")
          : totalStatistics.realizedIdeaCountSetting?.toLocaleString("ru-RU")}
      </span>
      <span className="stats__name">
        {declOfNum(totalStatistics.realizedIdeaCount || totalStatistics.realizedIdeaCountSetting || 0, [
          "идея",
          "идеи",
          "идей",
        ])}
        <br></br>
        {declOfNum(totalStatistics.realizedIdeaCount || totalStatistics.realizedIdeaCountSetting || 0, [
          "реализована",
          "реализованы",
          "реализовано",
        ])}
      </span>
    </div>
  );
};

export default Stats;
