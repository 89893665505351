import { isMobile } from "react-device-detect";
import expertGameBlocksMobile from "./constants/gameDataMobile";
import expertGameBlocks from "./constants/gameData";
import { BlockData } from "./ExpertGame.interface";

export const getGameData = (): BlockData[] =>
  (isMobile ? expertGameBlocksMobile : expertGameBlocks).map((item) => {
    const _pinCompletedPos = item.pinCompletedPos || item.pinPos;
    return {
      ...item,
      pinRelativePos: [item.pinPos[0] - item.pos[0], item.pinPos[1] - item.pos[1]],
      pinRelativeCompletedPos: [_pinCompletedPos[0] - item.pos[0], _pinCompletedPos[1] - item.pos[1]],
    };
  });
