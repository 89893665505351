import React from "react";
import "./ProgressBar.scss";
import roundPin from "../../../../../assets/birthday10/icons/round-pin.png";
import { ReactComponent as QuestionIcon } from "../../../../../assets/birthday10/icons/round-question.svg";
import { useMediaQuery } from "react-responsive";
import { mediumTabletWidth } from "../../../../../utils/constants/widthConstants";
import InfoButton from "../InfoButton/InfoButton";

interface ProgressBarProps {
  numerator: number;
  denominator: number;
  type: "pin" | "questions";
  tooltipText: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ numerator, denominator, type, tooltipText }) => {
  const isMediumTablet = useMediaQuery({ query: `(max-width: ${mediumTabletWidth}px)` });
  const MAX_BACKGROUND_WIDTH = isMediumTablet ? 83 : 137;
  const progress = (numerator / denominator) * MAX_BACKGROUND_WIDTH;

  return (
    <div className="progress-bar">
      <div className="progress-bar__icon">
        {type === "pin" && <img src={roundPin} alt="pin" />}
        {type === "questions" && <QuestionIcon />}
      </div>
      <div className="bar-container">
        <div className="bar-background"></div>
        {numerator != 0 && <div className="bar-foreground" style={{ width: `${progress}px` }}></div>}
        <div className="progress-text">
          {numerator}/{denominator}
        </div>
        <InfoButton tooltipText={tooltipText} tooltipId={`${type}Tooltip`} />
      </div>
    </div>
  );
};

export default ProgressBar;
