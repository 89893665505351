import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import IdeaCard from "../../../presentational/Cards/IdeaCard/IdeaCard";
import NextModal from "../../../presentational/Modals/NextModal/NextModal";
import { RootState } from "../../../../types/State.interface";
import { User } from "../../../../types/User.interface";

import "swiper/swiper.scss";
import "./IdeasPhotoViewer.scss";

const IdeasPhotoViewer = (props) => {
  const user: User = useSelector((state: RootState) => state.user.userDetails);
  const { current: currentProjects, completed: completedProjects } = useSelector((state: RootState) => state.projects);
  const ideaProjectHasLanding = useMemo(
    () =>
      (!!props.idea.projectId &&
        (currentProjects || []).concat(completedProjects || []).find(({ id }) => id === props.idea.projectId)
          ?.hasLanding) ||
      false,
    [props.idea.projectId, currentProjects, completedProjects]
  );

  const renderContent = () => {
    return (
      <div className="idea-photo-viewer">
        <div className="idea-photo-viewer__body">
          <IdeaCard
            idea={props.idea}
            isHideFooter={true}
            onAuthorClick={props.onAuthorClick}
            onLike={props.onLike}
            user={user}
          />
        </div>
        <div className="idea-photo-viewer__footer">
          {props.idea.projectId && (
            <div className="idea-photo-viewer__project">
              <Link
                to={
                  ideaProjectHasLanding
                    ? `/archive?id=${props.idea.projectId}`
                    : `/workresult?id=${props.idea.projectId}`
                }
              >
                {props.idea.projectName}
              </Link>
            </div>
          )}

          {!!props.idea.categoryId && (
            <div className="idea-photo-viewer__project">
              <Link
                to={{ pathname: `/propose/${props.idea.groupCode}`, state: { categoryName: props.idea.categoryName } }}
              >
                {props.idea.categoryName}
              </Link>
            </div>
          )}

          <div className="idea-photo-viewer__idea">
            Идея: <span>{props.idea.ideaName}</span>
          </div>
          {!!props.idea.address && (
            <div className="idea-photo-viewer__idea">
              Адрес: <span>{props.idea.address}</span>
            </div>
          )}
          {props.idea.showComment && !!props.idea.comment && (
            <div className="idea-photo-viewer__idea">
              Комментарий: <span>{props.idea.comment}</span>
            </div>
          )}

          <div className="idea-photo-viewer__number">#{props.idea.number}</div>
        </div>
      </div>
    );
  };

  return (
    <NextModal
      class={`idea-photo-viewer-modal ${props.className || ""}`}
      overlayClass="idea-photo-viewer-modal-overlay"
      isOpened={props.isOpened}
      canSlidePrev={props.canSlidePrev}
      canSlideNext={props.canSlideNext}
      onSlidePrev={props.onSlidePrev}
      onSlideNext={props.onSlideNext}
      onClose={props.onClose}
    >
      {renderContent()}
    </NextModal>
  );
};

export default IdeasPhotoViewer;
