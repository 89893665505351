import { useEffect, useRef } from "react";

export function useRefsEffect<T>(cb: (refs: T) => unknown, deps: unknown[], refValue: T) {
  const ref = useRef(refValue);
  ref.current = refValue;

  useEffect(() => {
    cb(ref.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
