import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ObjectsGatheringInstance, ObjectsGatheringProps } from "./ObjectsGathering.interface";
import IdeasGroupModal from "../../Modals/IdeasGroupModal/IdeasGroupModal";
import CrowdBirthday10Service, {
  IdeasGameResult,
  LandingIdeaDto,
  LandingIdeaGroupDto,
} from "../../../services/CrowdBirthday10.service";
import { ApiStatusCode } from "../../../../../../types/Common.interface";
import { IdeasGroupModalProps } from "../../Modals/IdeasGroupModal/IdeasGroupModal.interface";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../types/State.interface";
import { useToasts } from "react-toast-notifications";
import { AuthAction, AuthActionType, ComponentType } from "../../../../../../services/sudirService";
import { AppContext } from "../../../../../Root";
import IdeasGroupAllFoundModal from "../../Modals/IdeasGroupAllFoundModal/IdeasGroupAllFoundModal";
import ImageModal from "../../Modals/ImageModal/ImageModal";

const ObjectsGathering: FC<ObjectsGatheringProps> = ({ onGameInit, onGameDataUpdate, mapInstance }) => {
  const appContext = useContext(AppContext);
  const { addToast } = useToasts();
  const loggegIn = useSelector((state: RootState) => state.user.loggedIn);
  const [selectedObjectData, setSelectedObjectData] =
    useState<Parameters<ObjectsGatheringInstance["chooseObject"]>[0]>();
  const [ideaGroups, setIdeaGroups] = useState<Record<LandingIdeaGroupDto["number"], LandingIdeaGroupDto>>();
  const [gameResult, setGameResult] = useState<IdeasGameResult>();
  const [showFinalModal, setShowFinalModal] = useState(false);
  const [selectedIdea, setSelectedIdea] = useState<LandingIdeaDto>();

  const onGameDataUpdateRef = useRef(onGameDataUpdate);
  useEffect(() => {
    onGameDataUpdateRef.current = onGameDataUpdate;
  }, [onGameDataUpdate]);

  const groupIdsGatheredSetRef = useRef(new Set(gameResult?.groupIds || []));
  useEffect(() => {
    groupIdsGatheredSetRef.current = new Set(gameResult?.groupIds || []);
  }, [gameResult?.groupIds]);

  const fetchIdeaGroups = async () => {
    try {
      const ideaGroupRes = await CrowdBirthday10Service.ideaGroupGetAll();

      const { status, data, message } = ideaGroupRes;
      if (status === ApiStatusCode.OK) {
        setIdeaGroups(data.reduce((prev, curr) => ({ ...prev, [curr.number]: curr }), {}));
      } else {
        console.error(message);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchGameResult = async () => {
    try {
      const ideasGameRes = await CrowdBirthday10Service.getIdeasGameResult();
      const { status, data, message } = ideasGameRes;
      if (status === ApiStatusCode.OK) {
        setGameResult(data);
      } else {
        console.error(message);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const saveGame = async (group: LandingIdeaGroupDto, idea: LandingIdeaDto) => {
    try {
      const { status, data, message } = await CrowdBirthday10Service.saveIdeaGame({
        groupId: group.id,
        ideaId: idea.id,
      });
      if (status === ApiStatusCode.OK) {
        setGameResult(data);
        addToast("Вы нашли ненастоящую идею", {
          id: "crowd-birthday-10-object-gathering",
          appearance: "success",
          autoDismiss: true,
          type: "photo",
        });
        if (data.status === "FINISHED") {
          setShowFinalModal(true);
        }
      } else {
        console.error(message);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onGroupIdeaSelect = useCallback<IdeasGroupModalProps["onGroupIdeaSelect"]>(
    (group, idea) => {
      if (idea.actual || groupIdsGatheredSetRef.current.has(group.id)) {
        return;
      }

      if (!loggegIn) {
        const translate = mapInstance?.getTranslate();
        const action = new AuthAction({
          component: ComponentType.BIRTHDAY10_OBJECT_GATHERING,
          type: AuthActionType.GROUP_IDEA_FOUND,
          args: { num: group.number, ideaId: idea.id, ...translate },
        });
        return appContext.sudirService.authWithActions([action]);
      }

      saveGame(group, idea);
    },
    [loggegIn, mapInstance]
  );

  useEffect(() => {
    onGameInit({ chooseObject: setSelectedObjectData });
    fetchIdeaGroups();
  }, []);

  useEffect(() => {
    if (loggegIn) {
      fetchGameResult();
    } else {
      setGameResult(undefined);
    }
  }, [loggegIn]);

  useEffect(() => {
    if (selectedObjectData?.selectedIdeaId && ideaGroups) {
      const group = ideaGroups[selectedObjectData.num];
      const idea = group?.ideas.find(({ id }) => id === selectedObjectData.selectedIdeaId);
      if (group && idea) {
        onGroupIdeaSelect(group, idea);
      }
    }
  }, [selectedObjectData, ideaGroups, onGroupIdeaSelect]);

  useEffect(() => {
    onGameDataUpdateRef.current?.({ result: gameResult, groups: ideaGroups });
  }, [gameResult, ideaGroups]);

  useEffect(() => {
    if (showFinalModal) {
      setIdeaGroups(undefined);
    }
  }, [showFinalModal]);

  return (
    <>
      {!!ideaGroups?.[selectedObjectData?.num] && (
        <IdeasGroupModal
          isOpen
          onClose={() => setSelectedObjectData(undefined)}
          ideaGroupFound={groupIdsGatheredSetRef.current.has(ideaGroups[selectedObjectData.num].id)}
          ideaGroup={ideaGroups[selectedObjectData.num]}
          target={selectedObjectData.target}
          onGroupIdeaSelect={onGroupIdeaSelect}
          initialIdeaId={selectedObjectData.selectedIdeaId}
        />
      )}
      {showFinalModal && <IdeasGroupAllFoundModal isOpen onClose={() => setShowFinalModal(false)} position="right" />}
      {selectedIdea && <ImageModal isOpen onClose={() => setSelectedIdea(undefined)} imgSrc={selectedIdea.pictureId} />}
    </>
  );
};

export default ObjectsGathering;
