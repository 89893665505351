import { FC, useEffect, useRef, useState } from "react";
import "./ScreenLoader.scss";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../../utils/constants/widthConstants";
import classNames from "classnames";
interface ScreenLoaderProps {
  progress: number;
  onReady?: () => void;
}

const preloadImages = [
  require("../../../../../assets/birthday10/logo.svg").default,
  require("../../../../../assets/birthday10/bottom-trees.svg").default,
  require("../../../../../assets/birthday10/top-trees.svg").default,
];

const ScreenLoader: FC<ScreenLoaderProps> = ({ progress, onReady }) => {
  const hideTrees = useMediaQuery({ query: `(max-width: ${phoneWidth}px) or (max-height: 319px)` });
  const barRef = useRef<HTMLDivElement>(null);
  const [isComponentReady, setIsComponentReady] = useState(false);
  const loadedImages = useRef(0);
  const [isComponentMount, setIsComponentMount] = useState(true);

  useEffect(() => {
    if (progress >= 100) {
      const timerId = setTimeout(() => setIsComponentMount(false), 1000);
      return () => clearInterval(timerId);
    }
  }, [progress]);

  useEffect(() => {
    barRef.current.style.transform = `translateX(${Math.min(100, progress)}%)`;
  }, [progress]);

  useEffect(() => {
    const handleImageLoad = () => {
      loadedImages.current++;
      if (loadedImages.current === preloadImages.length) {
        setIsComponentReady(true);
        onReady?.();
      }
    };
    preloadImages.forEach((src) => {
      const image = new Image();
      image.onload = handleImageLoad;
      image.onerror = handleImageLoad;
      image.src = src;
    });
  }, []);

  return isComponentMount ? (
    <div
      className={classNames("crowd-birthday10-screen-loader", {
        "crowd-birthday10-screen-loader--not-ready": !isComponentReady,
      })}
    >
      {!hideTrees && (
        <div className="crowd-birthday10-screen-loader__trees crowd-birthday10-screen-loader__trees_top">
          <img src={require("../../../../../assets/birthday10/top-trees.svg").default} alt="" />
        </div>
      )}

      <div className="crowd-birthday10-screen-loader__content">
        <div className="crowd-birthday10-screen-loader__header">
          <img
            className="crowd-birthday10-screen-loader__logo"
            src={require("../../../../../assets/birthday10/logo.svg").default}
            alt=""
          />
          <p className="crowd-birthday10-screen-loader__title">10 лет платформе «Город Идей»</p>
        </div>
        <div className="crowd-birthday10-screen-loader__progress">
          <div className="crowd-birthday10-screen-loader__progress-bar" ref={barRef}></div>
        </div>
        <p className="crowd-birthday10-screen-loader__load-text">ЗАГРУЗКА...</p>
      </div>

      {!hideTrees && (
        <div className="crowd-birthday10-screen-loader__trees crowd-birthday10-screen-loader__trees_bottom">
          <img src={require("../../../../../assets/birthday10/bottom-trees.svg").default} alt="" />
        </div>
      )}
    </div>
  ) : null;
};

export default ScreenLoader;
