import "./Pin.scss";
import { PinProps } from "./Pin.interface";
import { forwardRef } from "react";

const Pin = forwardRef<HTMLDivElement, PinProps>(({ onClick, onMouseEnter }, ref) => {
  return (
    <div
      ref={ref}
      className="crowd-birthday-10-pin"
      onClick={onClick}
      onPointerEnter={onMouseEnter}
    >
      <div className="crowd-birthday-10-pin__spot" />
    </div>
  );
});

export default Pin;
