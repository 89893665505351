import { FC, useMemo } from "react";

import { AppModal } from "../../../../../containers/Modal/AppModal";
import Button from "../../Button/Button";
import { linkify } from "../../../../../../utils/url";
import { stripHTML } from "../../../../../../utils";

interface IncorrectAnswerModalProps {
  message: string;
  isOpen: boolean;
  onClose: () => void;
  onProposeIdea: () => void;
  onNext: () => void;
  position: "left" | "right";
}

const CorrectAnswerModal: FC<IncorrectAnswerModalProps> = ({
  isOpen,
  message,
  onClose,
  onProposeIdea,
  onNext,
  position,
}) => {
  const messageLinkified = useMemo(() => linkify(stripHTML(message), true), [message]);

  return (
    <AppModal
      center
      classNames={{
        modal: `correct-answer-modal crowd-birthday-10-modal ${position ? `crowd-birthday-10-modal--${position}` : ""}`,
      }}
      open={isOpen}
      blockScroll={true}
      closeOnEsc={true}
      closeOnOverlayClick={false}
      showHeader={false}
      onClose={onClose}
      overlayId="crowd-birthday-10"
    >
      <div className="crowd-birthday-10-modal__content">
        <p className=" crowd-birthday-10-modal__title">Ура! Правильный Выбор</p>

        <p className="crowd-birthday-10-modal__text" dangerouslySetInnerHTML={{ __html: messageLinkified }} />
        <div className="crowd-birthday-10-modal__buttons">
          <Button onClick={() => onProposeIdea()} text="Предложить идею" type="outlined" />
          <Button onClick={onNext} text="Продолжить" />
        </div>
      </div>
    </AppModal>
  );
};

export default CorrectAnswerModal;
