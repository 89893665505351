import React, { CSSProperties, FC, Fragment, Suspense, lazy } from "react";
import { BlockProps } from "./Block.interface";
import classNames from "classnames";
import "./Block.scss";
import PinQuestion from "../PinQuestion/PinQuestion";
const LottiePlayer = lazy(() => import("lottie-react"));

const Block: FC<BlockProps> = React.memo(
  ({
    isCompleted,
    groupCode,
    isDisabled = false,
    bgSrc,
    bgCompletedSrc,
    pinPos,
    pinCompletedPos,
    onPinClick,
    onPinMouseEnter,
    pinFocus = false,
  }) => {
    const [left, top] = isCompleted && pinCompletedPos ? pinCompletedPos : pinPos;
    const src = isCompleted ? bgCompletedSrc || bgSrc : bgSrc;

    const renderSources = () => {
      return src.map((item, i) => {
        let source: string | Object,
          style: CSSProperties = {},
          animationRepeat = false,
          size: [number, number];
        if (typeof item === "string") {
          source = item;
        } else if ("src" in item || "animationSrc" in item) {
          const transform = [];
          if (item.pos) {
            transform.push(`translate(${item.pos[0]}px, ${item.pos[1]}px)`);
          }
          if (transform.length) {
            style.transform = transform.join(" ");
          }

          if ("src" in item) {
            source = item.src;
          } else {
            source = item.animationSrc;
            animationRepeat = item.animationRepeat;
            if ("w" in source) {
              style.width = Number(source.w);
            }
            if ("h" in source) {
              style.height = Number(source.h);
            }
          }
          size = item.size;
        } else {
          source = item;
        }

        return (
          <Fragment key={i}>
            {typeof source === "string" ? (
              <img src={source} style={style} alt="" />
            ) : (
              <Suspense fallback="">
                <LottiePlayer
                  className="crowd-birthday-10-map-block__lottie"
                  style={style}
                  animationData={source}
                  autoPlay
                  loop={animationRepeat || false}
                  rendererSettings={{ viewBoxSize: size ? `0 0 ${size[0]} ${size[1]}` : undefined }}
                />
              </Suspense>
            )}
          </Fragment>
        );
      });
    };

    return (
      <div
        className={classNames({
          "crowd-birthday-10-map-block": true,
          "crowd-birthday-10-map-block_disabled": isDisabled,
          "crowd-birthday-10-map-block_completed": isCompleted,
        })}
      >
        {renderSources()}
        <div className="crowd-birthday-10-map-block__pin" style={{ transform: `translate(${left}px, ${top}px)` }}>
          <PinQuestion
            onClick={() => onPinClick(groupCode)}
            onMouseEnter={() => onPinMouseEnter(groupCode)}
            isCompleted={isCompleted}
            focus={pinFocus}
          />
        </div>
      </div>
    );
  }
);

export default Block;
