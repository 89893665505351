import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import Button from "../../presentational/Controls/Button/Button";
import Image from "../../presentational/Image/Image";
import "./GenericProjectPage.scss";
import { Project } from "../../../types/Projects.interface";

interface Props {
  project: Project;
  pageType: "announcement" | "current" | any;
  pageTitle: string;
  projectDates: string;
  projectDescription: string;
  defaultLogo: any;
  logo: any;
  loggedIn: boolean;
  participationLabel: string;
  joinProject: (projectId) => void;
  goToPlatform: (url) => void;
}

const GenericProjectPage = (props: Props) => {
  const title = props.pageType === "current" ? "«Город идей» — Текущий проект" : "«Город идей» — Следующий проект";
  const description =
    props.pageType === "current"
      ? "Присоединяйтесь к текущему тематическому проекту платформы «Город идей»."
      : "Присоединяйтесь к следующему тематическому проекту платформы «Город идей».";

  return (
    <div className={`project project-page--${props.pageType}`}>
      <MetaDecorator title={title} description={description} opengraph={{ title, description }} />

      <div className="project__header">
        <div className="project__wrapper">
          <div className="project__logo">
            <Image src={props.logo.squareSrc} alt="Логотип проекта" defaultSrc={props.defaultLogo.squareSrc} />
          </div>

          <div className="project__info">
            <div className="project__brackets-area">
              {props.pageType === "current" && <div className="project__label">текущий проект</div>}

              {props.pageType === "announcement" && (
                <div className="project__dates project__dates--announcement">{props.projectDates}</div>
              )}

              <div
                className="project__title"
                dangerouslySetInnerHTML={{
                  __html: props.pageTitle,
                }}
              ></div>
            </div>

            <div className="project__logo project__logo--mobile">
              <Image
                src={props.logo.squareMobileSrc}
                alt="Логотип проекта"
                defaultSrc={props.defaultLogo.squareMobileSrc}
              />
            </div>

            <div className={`join-area ${props.pageType === "current" ? "join-area--current" : ""}`}>
              {props.pageType === "current" && (
                <div className="project__dates project__dates--current">{props.projectDates}</div>
              )}

              {props.pageType === "current" && (
                <div className="project__button">
                  <Button
                    type="filled"
                    text="Перейти на проект"
                    onClick={() => props.goToPlatform(props.project.url)}
                  />
                </div>
              )}

              {props.pageType === "announcement" &&
                (props.participationLabel ? (
                  <div className="project__button">
                    <div className="join-area__subscriber-status">{props.participationLabel}</div>
                  </div>
                ) : (
                  <div className="project__button">
                    <Button type="filled" text="Присоединиться" onClick={() => props.joinProject(props.project.id)} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {props.projectDescription && (
        <div className="project__page-description user-text-style">
          <div dangerouslySetInnerHTML={{ __html: props.projectDescription }} />
        </div>
      )}
    </div>
  );
};

export default GenericProjectPage;
