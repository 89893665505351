// @flow

import React, { Component, useContext, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import dayjs from "dayjs";

import GenericProjectPage from "../../pages/GenericProject/GenericProjectPage";
import FadeIn from "../../presentational/FadeIn/FadeIn";
import Loader from "../../presentational/Loaders/Loader/Loader";
import { getUser } from "../../../store/actions/UserActions";
import { showInfoModal, hideInfoModal, setAppColor } from "../../../store/actions/LayoutActions";
import {
  getStringDate,
  userHasRoleInProject,
  isFrontman,
  isParticipant,
  isExParticipant,
  isExpert,
  isTeamMember,
  findUrlParam,
  removeUrlParam,
} from "../../../utils";
import UserService from "../../../services/userService";
import ProjectService from "../../../services/projectService";
import GenericProjectLoader from "../../presentational/Loaders/GenericProjectLoader/GenericProjectLoader";
import { store } from "../../../store/store";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../services/sudirService";
import { AppContext } from "../../Root";
import { SUCCESS } from "../../../types/statusCodes";
import Button from "../../presentational/Controls/Button/Button";
import { RootState } from "../../../types/State.interface";
import { ProjectTypeEnum } from "../../../types/Projects.interface";
import PointsService from "../../../services/pointsService";
import { AppColorsEnum } from "../../App.interface";
import { selectIsUserReady } from "../../../store/reducers/profile";

export function GenericProjectContainer(props) {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isUserReady = useSelector(selectIsUserReady);

  const user = useSelector((state: RootState) => state.user);

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState<any>(null);

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.WHITE));
    loadProject();
  }, []);

  useEffect(() => {
    if (history.location.pathname && project) {
      loadProject();
    }
  }, [history.location.pathname]);

  // Actions after auth
  useEffect(() => {
    if (project && isUserReady) {
      const actions = appContext.sudirService.getActions(ComponentType.GENERIC_PROJECT_CONTAINER);
      if (actions.length) {
        const joinProjectAction = actions.find((a) => a.type === AuthActionType.JOIN_PROJECT);

        if (joinProjectAction && !history.location.state?.alreadyJoined) {
          joinProject(joinProjectAction.args.projectId);
        }

        const redirectAction = actions.find((a) => a.type === AuthActionType.REDIRECT);
        if (redirectAction) {
          window.location.href = redirectAction.args.redirectLink;
        }
      }
    }
  }, [project, isUserReady]);

  // Join project if clicked on bunner btn
  useEffect(() => {
    if (history.location.state?.projectId && project) {
      joinProject(history.location.state.projectId);
    }
  }, [history.location.state, project]);

  const loadProject = () => {
    setLoading(true);
    const projectId = findUrlParam("id", window.location.search);
    ProjectService.getProjectInfo(projectId)
      .then((response) => {
        if (response.status === SUCCESS) {
          setProject(response.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const getPageType = () => {
    return getTypeFromUrl(history.location.pathname);
  };

  const getTypeFromUrl = (url) => {
    if (url.startsWith("/announcement")) {
      return "announcement";
    } else if (url.startsWith("/current")) {
      return "current";
    }
  };

  const getPageTitle = () => {
    return project.nameOnPromo || project.title;
  };

  const getProjectDatesString = () => {
    return getPageType() === "announcement"
      ? getAnnouncementDatesString(project)
      : getStringDate(project, "dateToDate");
  };

  const getAnnouncementDatesString = (project) => {
    if (project.start && project.finish) {
      if (project.isMain || project.parentProjectId) {
        return `${getStringDate(project, "startYear")} год`;
      } else {
        return getStringDate(project, "dateToDate");
      }
    } else if (project.announcement && project.announcement.announcementDate) {
      if ((project.isMain || project.parentProjectId) && project.start) {
        return `${getStringDate(project, "startYear")} год`;
      } else {
        return project.announcement.announcementDate;
      }
    }
    return "";
  };

  const getProjectDescription = () => {
    if (getPageType() === "announcement") {
      return project.announcementDescription;
    }

    if (getPageType() === "current") {
      return project.description;
    }
  };

  const getDefaultLogo = () => {
    let squareSrc;
    let squareMobileSrc;

    const type = getPageType();
    if (type === "announcement") {
      try {
        squareSrc = require("../../../assets/logo/default/announce.jpeg").default;
        squareMobileSrc = require("../../../assets/logo/default/announce_m.png").default;
      } catch (err) {
        console.log(err);
      }
    }

    if (type === "current") {
      try {
        squareSrc = require("../../../assets/logo/default/current.jpeg").default;
        squareMobileSrc = require("../../../assets/logo/default/current_m.png").default;
      } catch (err) {
        console.log(err);
      }
    }

    return {
      squareSrc,
      squareMobileSrc,
    };
  };

  const getLogo = () => {
    let logo = {
      squareSrc: project.logo.square ? `/uploads/get/${project.logo.square}` : getDefaultLogo().squareSrc,
      squareMobileSrc: project.logo.horizontal
        ? `/uploads/get/${project.logo.horizontal}`
        : getDefaultLogo().squareMobileSrc,
    };

    return logo;
  };

  const getParticipationLabel = () => {
    if (!user.loggedIn) return "";

    if (userHasRoleInProject(user.userDetails, project.id)) {
      if (isFrontman(user.userDetails, project.frontmanIds)) {
        return "Вы лицо проекта";
      } else if (isParticipant(user.userDetails, project.id)) {
        return "Участвую";
      } else if (isExParticipant(user.userDetails, project.id)) {
        return "Вы удалены с проекта";
      } else if (isExpert(user.userDetails, project.id)) {
        return "Вы эксперт проекта";
      } else if (isTeamMember(user.userDetails, project.id)) {
        return "Вы член команды проекта";
      } else if (isParticipant(user.userDetails, project.id)) {
        return "Участвую";
      }
    }

    return "";
  };

  const joinProject = (projectId) => {
    if (!user.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.GENERIC_PROJECT_CONTAINER,
        type: AuthActionType.JOIN_PROJECT,
        args: {
          projectId,
        },
      };

      const mainContainerData: AuthActionParam = {
        component: ComponentType.MAIN,
        type: AuthActionType.REDIRECT_AFTER_REG_FORM,
        args: {
          projectId,
          redirectLink: location.pathname + location.search,
        },
      };

      const action1 = new AuthAction(thisContainerData);
      const action2 = new AuthAction(mainContainerData);

      return appContext.sudirService.authWithActions([action1, action2]);
    }

    if (userHasRoleInProject(user.userDetails, projectId)) {
      const exParticipant = isExParticipant(user.userDetails, projectId);
      const message = exParticipant ? "Вы удалены с проекта." : "Вы уже являетесь участником проекта.";
      const handler = () => {
        dispatch(getUser());
        dispatch(hideInfoModal());
        if (exParticipant) {
          history.replace(history.location.pathname);
        }
      };
      return dispatch(showInfoModal(message, renderOkButton(handler), handler));
    }

    UserService.addToProjects({ ids: [projectId] })
      .then(async (data) => {
        const handler = () => {
          dispatch(hideInfoModal());
          dispatch(getUser());
        };

        if (data.status === SUCCESS) {
          const msg = await PointsService.getWelcomePointsMessage(
            user.userDetails,
            project.title,
            ProjectTypeEnum.ANNOUNCEMENT
          );

          return dispatch(showInfoModal(msg, renderOkButton(handler), handler));
        }

        dispatch(showInfoModal(data.message, renderOkButton(handler), handler));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goToPlatform = (link) => {
    if (!user.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.GENERIC_PROJECT_CONTAINER,
        type: AuthActionType.REDIRECT,
        args: {
          redirectLink: link,
          absolutePath: true,
        },
      };

      const mainContainerData: AuthActionParam = {
        component: ComponentType.MAIN,
        type: AuthActionType.REDIRECT_AFTER_REG_FORM,
        args: {
          absolutePath: true,
          redirectLink: link,
        },
      };

      const action1 = new AuthAction(thisContainerData);
      const action2 = new AuthAction(mainContainerData);

      return appContext.sudirService.authWithActions([action1, action2]);
    }

    window.location.href = link;
  };

  // RENDER

  const renderOkButton = (handler) => {
    return <Button type="outlined" text="Хорошо" onClick={handler} />;
  };

  return (
    <div className="project-container">
      {!loading && project ? (
        <FadeIn>
          <GenericProjectPage
            project={project}
            pageType={getPageType()}
            pageTitle={getPageTitle()}
            projectDates={getProjectDatesString()}
            projectDescription={getProjectDescription()}
            defaultLogo={getDefaultLogo()}
            logo={getLogo()}
            loggedIn={user.loggedIn}
            participationLabel={getParticipationLabel()}
            joinProject={(projectId) => joinProject(projectId)}
            goToPlatform={goToPlatform}
          />
        </FadeIn>
      ) : (
        <GenericProjectLoader />
      )}
    </div>
  );
}

export default GenericProjectContainer;
