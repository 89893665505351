import { birthday10LandingUrl } from "../components/pages/CrowdBirthday10/CrowdBirthday10.constants";
import { sections } from "../components/pages/CrowdBirthday9/CrowdBirthday9.helper";

export const hiddenURLs = [
  "/teamLogin",
  "/teamLogin/",
  "/restore",
  "/restore/",
  "/moderation",
  "/moderation/",
  "/ideaModeration",
  "/ideaModeration/",
  "/license",
  "/license/",
  "/pointsRules",
  "/pointsRules/",
  "/changePassword",
  "/changePassword/",
  "/restorePassword",
  "/newYearRules",
  "/restorePassword/",
  "/newpassword",
  "/newpassword/",
  "/birthday",
  birthday10LandingUrl,
  "/city",
  ...sections.filter((section) => !!section.href).map((section) => section.href),
].map((url) => url.toLowerCase());

export const emailVerifiedGuardWhitelist = ["/", "/successEmailConfirmation", "/failedEmailConfirmation"];

export const notExistingURLs = ["/newYear"];
