import { FC } from "react";
import { ImageModalProps } from "./ImageModal.interface";
import "./ImageModal.scss";
import { AppModal } from "../../../../../containers/Modal/AppModal";

const ImageModal: FC<ImageModalProps> = ({ isOpen, onClose, imgSrc }) => {
  return (
    <AppModal
      center
      classNames={{
        modal: "crowd-birthday-10-modal crowd-birthday-10-image-modal",
      }}
      open={isOpen}
      blockScroll={true}
      closeOnEsc={true}
      closeOnOverlayClick={false}
      showHeader={false}
      onClose={onClose}
    >
      <img className="crowd-birthday-10-image-modal__img" src={`/uploads/get/${imgSrc}`} />
    </AppModal>
  );
};

export default ImageModal;
